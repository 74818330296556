import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, Prompt } from "react-router-dom";

// mui
import { Grid, Button } from "@mui/material";

// constant
import {
  PENDING,
  PENDING_WITH_EDIT,
  ACCEPTED,
  PAYER_EDITED,
  PROVIDER_EDITED,
  PROVIDER_SUBMITTED_FOR_REVIEW,
  PAYER_REOPENED,
} from "../../constants/Billings/billingStatus";

// apis
import {
  fetchBillPatient,
  providerSubmit,
  reopenBill,
  acceptBill,
} from "../../api/Invoices/BillPatientApi";
import editBillItems from "../../api/Billings/editBillItems.service";

// style
import { Container } from "./styles";

// components
import ModalBillItem from "./container/ModalBillItem";
import DialogConfirm from "./container/DialogConfirm";
import AlertMessage from "./container/AlertMessage";

// functions
import PatientDetail from "./PatientDetail";
import VisitDetail from "./VisitDetail";
import InvoiceItem from "./InvoiceItem";
import { IBillItemForEdit, IEditItemList, IPatientInvoice } from "./type";

function PatientInvoice() {
  const { visitId, categoryId } =
    useParams<{ visitId: string; categoryId: string }>();
  const { appData }: any = useSelector((state) => state);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;

  const [data, setData] = useState<IPatientInvoice>();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [billItem, setBillItem] = useState<IBillItemForEdit | null>(null);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [editedBillItemList, setEditedBillItemList] = useState<IEditItemList[]>(
    []
  );

  const isProvider = data?.visitResult?.hospCode === hospCode;
  const isSubmit =
    isProvider &&
    [PENDING_WITH_EDIT, PENDING].includes(data?.billResult?.status || "");
  const isReopen = !isProvider && data?.billResult?.status === ACCEPTED;
  const isAcceptForProvider =
    isProvider && data?.billResult?.status === PAYER_EDITED;
  const isAcceptForPayer =
    !isProvider &&
    [PROVIDER_EDITED, PROVIDER_SUBMITTED_FOR_REVIEW].includes(
      data?.billResult?.status || ""
    );
  const providerEditBillItem =
    isProvider &&
    [
      PENDING_WITH_EDIT,
      PENDING,
      PAYER_EDITED,
      PROVIDER_EDITED,
      PAYER_REOPENED,
    ].includes(data?.billResult?.status || "");
  const payerEditBillItem =
    !isProvider &&
    [
      PROVIDER_SUBMITTED_FOR_REVIEW,
      PROVIDER_EDITED,
      PAYER_EDITED,
      PAYER_REOPENED,
    ].includes(data?.billResult?.status || "");

  const fetchData = async () => {
    const params = { idToken, visitId };
    const result = await fetchBillPatient(params);
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [idToken, hospCode, open, status]);

  // prompt if it has unsaved bill items edit
  useEffect(() => {
    if (editedBillItemList.length > 0) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [editedBillItemList]);

  function onEditBillItem(item: IBillItemForEdit) {
    setOpen(true);
    setBillItem(item);
  }

  const onCloseModal = () => {
    setOpen(false);
  };

  const onEditedBillItem = (editedBillItem: IEditItemList) => {
    // if edit bill item push it to editedBillItemList
    if (!Number.isNaN(+editedBillItem.latestFinalPrice)) {
      const billItemInListIndex = editedBillItemList.findIndex(
        (item) => item.billItemId === editedBillItem.billItemId
      );

      // if it has this bill item in editedBillItemList remove first
      if (billItemInListIndex >= 0) {
        editedBillItemList.splice(billItemInListIndex, 1);
      }

      setEditedBillItemList([...editedBillItemList, editedBillItem]);
    }

    setOpen(false);
  };

  const onConfirmBilling = async () => {
    const body = { idToken, visitId };

    if (isSubmit) {
      const result = await providerSubmit(body);
      setMessage(result?.message);
      setStatus(result?.stausMessage);
      setOpenDialog(false);
    }

    if (isReopen) {
      const result = await reopenBill(body);
      setMessage(result?.message);
      setStatus(result?.stausMessage);
      setOpenDialog(false);
    }

    if (isAcceptForProvider || isAcceptForPayer) {
      const result = await acceptBill(body);
      setMessage(result?.message);
      setStatus(result?.stausMessage);
      setOpenDialog(false);
    }

    setTimeout(() => {
      setStatus(null);
    }, 1000);
  };

  const onConfirmEditBillItems = async () => {
    const result = await editBillItems({
      editedBillItemList,
      visitId,
      idToken,
      categoryId,
      visitHospCode: data?.visitResult?.hospCode || "",
    });

    setEditedBillItemList([]);
    setMessage(result?.message);
    setStatus(result?.stausMessage);
  };

  const onOpenDialog = () => setOpenDialog(true);
  const onCloseDialog = () => setOpenDialog(false);
  const onCloseMessage = () => setStatus(null);

  return (
    <>
      <Prompt
        when={editedBillItemList.length > 0}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Container className="!mt-20">
        <Grid container direction="column">
          {data?.visitResult?.patient && (
            <PatientDetail
              patient={data?.visitResult?.patient}
              hospmain={data?.visitResult?.hospmain}
              hospCodeProvider={data?.visitResult?.hospCode}
            />
          )}
          {data?.visitResult && (
            <VisitDetail
              billStatus={data?.billResult?.status}
              visit={data?.visitResult}
              isProvider={isProvider}
            />
          )}
          {Array.isArray(data?.billItemResult) &&
            data?.nonCalculateCapTotal &&
            data?.billResult && (
              <InvoiceItem
                items={data?.billItemResult}
                nonCalculateCapTotal={data?.nonCalculateCapTotal}
                bill={data?.billResult}
                providerEditBillItem={providerEditBillItem}
                payerEditBillItem={payerEditBillItem}
                onEditBillItem={onEditBillItem}
                editedBillItemList={editedBillItemList}
              />
            )}
          {open && billItem && (
            <ModalBillItem
              open={open}
              onCloseModal={onCloseModal}
              onEditedBillItem={onEditedBillItem}
              billItem={billItem}
            />
          )}

          {editedBillItemList.length > 0 ? (
            <div className="flex justify-end fixed bottom-3 right-8">
              <Button
                className="mt-3 opacity-90 shake"
                color="primary"
                variant="contained"
                onClick={onConfirmEditBillItems}
              >
                ยืนยันการแก้ไขรายการบิล
              </Button>

              <Button
                className="mt-3 ml-2 opacity-90"
                color="secondary"
                variant="contained"
                onClick={() => setEditedBillItemList([])}
              >
                ยกเลิก
              </Button>
            </div>
          ) : (
            <>
              {isSubmit && (
                <Button
                  className="bg-yellow-300 mt-3
                hover:bg-yellow-400"
                  variant="contained"
                  onClick={onOpenDialog}
                >
                  ส่งเรียกเก็บ
                </Button>
              )}

              {(isAcceptForProvider || isAcceptForPayer) && (
                <Button
                  className="mt-3"
                  variant="contained"
                  color="primary"
                  onClick={onOpenDialog}
                >
                  จบการเรียกเก็บ
                </Button>
              )}

              {isReopen && (
                <Button
                  className="bg-green-300 mt-3
                hover:bg-green-400"
                  variant="contained"
                  onClick={onOpenDialog}
                >
                  เปิดเคสใหม่
                </Button>
              )}
            </>
          )}

          {openDialog && (
            <DialogConfirm
              onClose={onCloseDialog}
              open={openDialog}
              onConfirm={onConfirmBilling}
            />
          )}
          {status === 200 && (
            <AlertMessage
              title="Success"
              severity="success"
              message={message}
              onCloseMessage={onCloseMessage}
            />
          )}
          {status === 400 && (
            <AlertMessage
              title="Failed"
              severity="error"
              message={message}
              onCloseMessage={onCloseMessage}
            />
          )}
        </Grid>
      </Container>
    </>
  );
}

export default PatientInvoice;
