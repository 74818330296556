import { Close, FileOpen } from "@mui/icons-material";
import {
  Alert,
  AlertColor,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteFileToStorage from "../../../../api/Refers/ReferOut/DeleteFileInStorage";
import getFileFromStorage from "../../../../api/Refers/ReferOut/getFileFromStorage";
import { IReferralStorageAttachment } from "@/types/refers";

interface IReferPreviewAttachDialog {
  open: boolean;
  onClose(): void;
  storageAttachment: IReferralStorageAttachment[];
  token: string;
  fromHospCode: string;
  userHospCode: string;
}
interface IResponseAttactment {
  status: AlertColor;
  message: string;
}
const ReferPreviewAttachDialog = ({
  open,
  onClose,
  storageAttachment,
  token,
  fromHospCode,
  userHospCode,
}: IReferPreviewAttachDialog) => {
  const [sources, setSources] = useState<IReferralStorageAttachment[]>([]);
  const [response, setResponse] = useState<IResponseAttactment | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const filetypes = /image/;
  const onDeleteFile = async (fileName: string, imageId: string) => {
    setOpenSnackbar(true);
    const resp = await DeleteFileToStorage({ token, imageId, fileName });
    setSources((presource) =>
      presource.filter((source) => source._id !== imageId)
    );
    setResponse(resp);
    if (sources.length === 1) {
      // เหลืออันสุดท้าย
      setTimeout(() => {
        setOpenSnackbar(false);
        onClose();
      }, 500);
    }
  };
  const fetchFileAndSetState = async (
    attachments: IReferralStorageAttachment[]
  ) => {
    const convertSources: IReferralStorageAttachment[] = [];
    for (let index = 0; index < attachments.length; index++) {
      const attachment = attachments[index];
      const url = await getFileFromStorage(attachment.attachmentName, token);
      convertSources.push({ ...attachment, url });
    }
    setSources(convertSources);
  };
  useEffect(() => {
    if (storageAttachment) {
      fetchFileAndSetState(storageAttachment);
    }
  }, [storageAttachment]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="refer-dialog-attach"
    >
      <DialogTitle id="alert-dialog-title">
        <strong>Preview File</strong>
      </DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-2 mb-6">
          {sources.map((attactment, index) => (
            <div
              className="my-2 mx-2 h-96 overflow-scroll"
              key={attactment._id}
            >
              {userHospCode === fromHospCode && (
                <button
                  className="sticky left-full bg-red-100 rounded-md top-3"
                  onClick={() =>
                    onDeleteFile(attactment.attachmentName, attactment?._id)
                  }
                >
                  <Close color="error" />
                </button>
              )}
              {filetypes.test(attactment.contentType) ? (
                <img
                  src={attactment.url}
                  key={`${index}-${attactment.url}`}
                  className="w-full h-64"
                />
              ) : (
                <>
                  <a
                    href={attactment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex flex-col justify-center items-center w-full h-64 border-2 border-gray-100 bg-gray-100 rounded-sm">
                      <FileOpen fontSize="large" />
                      <p>{attactment.attachmentName}</p>
                    </div>
                  </a>
                </>
              )}

              <p>{attactment.descriptionAttachment}</p>
            </div>
          ))}
        </div>
      </DialogContent>
      {openSnackbar && response && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={response?.status}
            sx={{ width: "100%" }}
          >
            {response?.message}
          </Alert>
        </Snackbar>
      )}
    </Dialog>
  );
};

export default ReferPreviewAttachDialog;
