import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// components
import ReferNavbar from "./ReferNavbar";
import ReferCard from "./ReferCard";

// functions
import { shortHospName } from "../../functions/FuncPerjer";
import {
  DateTimeToStrDate,
  StrToDateTime,
  delDays,
} from "../../functions/FuncDateTimes";

// actions
import appPropertySet from "../../actions/appPropertySet";
import fetchRefers from "../../actions/Refers/fetchRefers";

// orms
import orm from "../../models/index";

const session = orm.session();

class ReferOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cid: "",
      hospCodeData: [],
      ReferDatas: [],
      hospCode: "all",
      selectedDate: null,
      datePickerChange: false,
      referPoint: "all",
      startDate: new Date(),
      endDate: new Date(),
      Scid: "",
      Sname: "",
      fetchExpired: false,
      fromHospCode: null,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.hospCodeChange = this.hospCodeChange.bind(this);
    this.getReferDatas = this.getReferDatas.bind(this);
    this.setHospCodeData = this.setHospCodeData.bind(this);
    this.setReferDatas = this.setReferDatas.bind(this);
    this.onReferPointChange = this.onReferPointChange.bind(this);
    this.setAppData = this.setAppData.bind(this);
    this.getReferCidDatas = this.getReferCidDatas.bind(this);
    this.onCidChange = this.onCidChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    const { match, appData, history } = this.props;

    const { params } = match;

    if (!params.startDate && appData.loginData) {
      this.setState({ selectedDate: new Date() });
      this.setState({ startDate: new Date() });
      this.setState({ endDate: new Date() });

      history.push(
        `/refer-out/${DateTimeToStrDate(new Date())}/${DateTimeToStrDate(
          new Date()
        )}`
      );
      this.getReferDatas(
        DateTimeToStrDate(new Date()),
        this.state.fromHospCode
      );
    }

    if (
      (params.startDate !== startDate || params.endDate !== endDate) &&
      params.startDate &&
      params.endDate &&
      appData.loginData
    ) {
      this.setState({ selectedDate: StrToDateTime(params.startDate) });
      this.setState({ startDate: StrToDateTime(params.startDate) });
      this.setState({ endDate: StrToDateTime(params.startDate) });

      history.push(`/refer-out/${params.startDate}/${params.endDate}`);
      this.getReferDatas(params.startDate, this.state.fromHospCode);
    }
    setInterval(() => {
      this.setState({ fetchExpired: true });
    }, 300000); // 5 minute
  }

  componentDidUpdate() {
    const { startDate, selectedDate, datePickerChange, fetchExpired } =
      this.state;
    const { match, appData, history } = this.props;

    const { params } = match;

    if (
      selectedDate !== startDate &&
      selectedDate &&
      datePickerChange &&
      appData.loginData
    ) {
      this.setStartDate();

      history.push(
        `/refer-out/${DateTimeToStrDate(selectedDate)}/${DateTimeToStrDate(
          selectedDate
        )}`
      );
      this.getReferDatas(
        DateTimeToStrDate(selectedDate),
        this.state.fromHospCode
      );
    }

    if (fetchExpired) {
      this.getReferDatas(
        DateTimeToStrDate(selectedDate),
        this.state.fromHospCode
      );
      this.setState({ fetchExpired: false });
    }

    if (!datePickerChange && appData.loginData) {
      if (
        params.startDate &&
        params.startDate !== DateTimeToStrDate(selectedDate)
      ) {
        this.setSelectedDate();

        history.push(`/refer-out/${params.startDate}/${params.startDate}`);
        this.getReferDatas(params.startDate, this.state.fromHospCode);
      }
    }
  }

  onDateChange(date, fromHospCode) {
    this.setState({ datePickerChange: true });
    this.setState({ selectedDate: date });
    this.setState({ fromHospCode });
  }

  onCidChange(e) {
    const re = /^[0-9\b]+$/;

    if (re.test(e.target.value)) {
      this.setState({ Scid: e.target.value });
      this.setState({ Sname: "" });
    } else {
      this.setState({ Scid: "" });
      this.setState({ Sname: e.target.value });
    }

    this.setState({ cid: e.target.value });
  }

  onReferPointChange(e) {
    this.setState({ hospCode: "all" });
    this.setState({ referPoint: e.target.value });

    let referData = [];

    if (e.target.value !== "all") {
      if (e.target.value === "er") {
        referData = session.ReferDatas.all()
          .filter((ReferDatas) => ReferDatas.data.referPoint === "ER")
          .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
          .toRefArray();
      } else if (e.target.value === "ipd") {
        referData = session.ReferDatas.all()
          .filter((ReferDatas) => ReferDatas.data.referPoint === "IPD")
          .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
          .toRefArray();
      } else {
        referData = session.ReferDatas.all()
          .filter(
            (ReferDatas) =>
              ReferDatas.data.referPoint !== "ER" &&
              ReferDatas.data.referPoint !== "IPD"
          )
          .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
          .toRefArray();
      }
    } else {
      referData = session.ReferDatas.all()
        .filter((ReferDatas) => ReferDatas.data._id !== "")
        .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
        .toRefArray();
    }

    this.setState({ ReferDatas: referData });
  }

  setStartDate() {
    const { selectedDate } = this.state;

    this.setState({ startDate: selectedDate });
    this.setState({ datePickerChange: false });
  }

  setSelectedDate() {
    const { match } = this.props;

    const { params } = match;

    this.setState({ selectedDate: StrToDateTime(params.startDate) });
  }

  setHospCodeData() {
    const { appData } = this.props;

    const referDb = appData.emrData.referDatas;

    session.ReferHosp.delete({
      where(record) {
        return record.id !== "";
      },
    });

    referDb.forEach((val) => {
      session.ReferHosp.upsert({
        id: val.data.toHospCode,
        hospName: val.toHospName
          ? shortHospName(val.toHospName)
          : val.data.toHospCode,
      });
    });

    const hospCodeData2 = session.ReferHosp.all()
      .filter((ReferHosp) => ReferHosp.id !== "")
      .orderBy((ReferHosp) => ReferHosp.hospName, "asc")
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });
  }

  setReferDatas() {
    const { appData } = this.props;

    const referDb = appData.emrData.referDatas;

    session.ReferDatas.delete({
      where(record) {
        return record._id !== "";
      },
    });

    referDb.forEach((val) => {
      session.ReferDatas.upsert(val);
    });

    const referData = session.ReferDatas.all()
      .filter((ReferDatas) => ReferDatas.data._id !== "")
      .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
      .toRefArray();

    this.setState({ ReferDatas: referData });
  }

  getReferDatas(startDate, fromHospCode, incomplete = false) {
    const { appData, appProperty, handleFetchRefers } = this.props;

    const dataRequest = {
      idToken: appData.idToken,
      startDate,
      endDate: startDate,
      fromHospCode: fromHospCode || appData.loginData.hospCode,
      toHospCode: "",
      limit: appProperty.visitLimit,
      incomplete,
    };

    const res = handleFetchRefers(dataRequest);

    res.then(
      () => {
        this.setState({ hospCode: "all" });
        this.setState({ referPoint: "all" });

        this.setHospCodeData();
        this.setReferDatas();

        const appPropertyData = {
          visitSelect: "",
          FetchingStatus: false,
        };

        this.setAppData(appPropertyData);
      },
      (e) => {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert("การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
        }
      }
    );
  }

  getReferCidDatas(e) {
    const { cid, Scid, Sname } = this.state;
    const { appData, appProperty, handleFetchRefers } = this.props;

    e.preventDefault();

    if (cid !== "") {
      let tmpCid = "";
      let name = "";

      if (Scid.trim().length === 13) {
        tmpCid = Scid;
      }

      if (Sname.trim().length > 0 && Sname !== "") {
        name = Sname;
      }

      if (tmpCid <= "" && name <= "") {
        alert("กรุณากรอก CID / ชื่อ สกุล ไม่ถูกต้อง !");
      } else {
        const std = DateTimeToStrDate(delDays(new Date(), 366 * 3));
        const ste = DateTimeToStrDate(new Date());

        const dataRequest = {
          idToken: appData.idToken,
          startDate: std,
          endDate: ste,
          fromHospCode: appData.loginData.hospCode,
          toHospCode: "",
          cid: tmpCid,
          name,
          limit: appProperty.visitLimit,
        };

        const res = handleFetchRefers(dataRequest);

        res.then(
          () => {
            this.setState({ hospCode: "all" });
            this.setState({ referPoint: "all" });

            this.setHospCodeData();
            this.setReferDatas();

            const appPropertyData = {
              visitSelect: "",
              FetchingStatus: false,
            };

            this.setAppData(appPropertyData);
          },
          (err) => {
            // TypeError: Throwing
            if (err.status !== 200) {
              alert("การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
            }
          }
        );
      }
    }
  }

  setAppData(appPropertyData) {
    const { handleAppPropertySet } = this.props;

    handleAppPropertySet(appPropertyData);
  }

  hospCodeChange(e) {
    this.setState({ referPoint: "all" });
    this.setState({ hospCode: e.target.value });
    let referData = [];

    if (e.target.value !== "all") {
      referData = session.ReferDatas.all()
        .filter((ReferDatas) => ReferDatas.data.toHospCode === e.target.value)
        .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
        .toRefArray();
    } else {
      referData = session.ReferDatas.all()
        .filter((ReferDatas) => ReferDatas.data._id !== "")
        .orderBy((ReferDatas) => ReferDatas.data.referDateTime, "desc")
        .toRefArray();
    }

    this.setState({ ReferDatas: referData });
  }

  handleRefresh() {
    console.log("referout handle refresh");
    this.setState({ fetchExpired: true });
  }

  render() {
    const {
      selectedDate,
      hospCode,
      hospCodeData,
      referPoint,
      cid,
      ReferDatas,
    } = this.state;

    return (
      <div id="refer-out">
        <ReferNavbar
          title="Refer Out"
          cid={cid}
          hospCode={hospCode}
          referPoint={referPoint}
          hospCodeData={hospCodeData}
          selectedDate={selectedDate}
          onReferPointChange={this.onReferPointChange}
          getReferCidDatas={this.getReferCidDatas}
          getReferDatas={(date) => this.getReferDatas(date)}
          hospCodeChange={this.hospCodeChange}
          onCidChange={this.onCidChange}
          onDateChange={(date, fromHospCode) =>
            this.onDateChange(date, fromHospCode)
          }
        />

        {ReferDatas.length > 0 && (
          <div
            className="grid gap-4 p-4 bg-gray-100
            md:grid-cols-2
            lg:grid-cols-3"
          >
            {ReferDatas.map((referral, index) => (
              <ReferCard
                key={referral._id}
                actionRefer={false}
                changeRefer={false}
                cardIndex={index + 1}
                referral={referral}
                onRefresh={this.handleRefresh}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAppPropertySet: (appPropertyData) =>
      dispatch(appPropertySet(appPropertyData)),
    handleFetchRefers: (dataRequest) => dispatch(fetchRefers(dataRequest)),
  };
}

ReferOut.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }).isRequired,
  appData: PropTypes.shape({
    loginData: PropTypes.shape({
      username: PropTypes.string,
      hospCode: PropTypes.string,
    }),
    idToken: PropTypes.string,
    emrData: PropTypes.shape({
      referDatas: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
  appProperty: PropTypes.shape({
    visitLimit: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleAppPropertySet: PropTypes.func.isRequired,
  handleFetchRefers: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferOut);
