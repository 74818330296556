import React from "react";
import { IReferralPatientEMR } from "@/types/visit";
import VisitReferralItem from "./VisitReferralItem";

interface IReferralsProps {
  referrals: IReferralPatientEMR[];
  patientFullName: { title: string; firstName: string; lastName: string };
  idToken: string;
  onRefresh(): void;
}
function VisitReferrals({
  referrals,
  patientFullName,
  idToken,
  onRefresh,
}: IReferralsProps) {
  return (
    <div>
      {referrals?.map((referral, index) =>
        referral.fromHospital && referral.toHospital ? (
          <VisitReferralItem
            key={referral._id}
            referral={referral}
            idToken={idToken}
            patientFullName={patientFullName}
            onRefresh={onRefresh}
          />
        ) : (
          <div
            key={index}
            className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
          >
            <div className="h-10 px-5 py-2 border-b-2 border-gray-200">
              <h6 className="text-red-500">{`Referral ID: ${referral.data.referoutId}`}</h6>
            </div>
            <div className="text-center my-2">
              <h6>ไม่มีข้อมูล</h6>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default VisitReferrals;
