import React, { useState, useRef } from "react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import StyledCalendar from "./css/style";
import FullCalendar from "@fullcalendar/react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import ScheduleForm from "./components/ScheduleForm";
import CalendarToolbar from "./components/CalendarToolbar";
import MyHospFilter from "./components/MyHospFilter";
import { IDoctor, TClinic } from "../../types/clinic";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import fetchSchedules from "../../api/AppointmentOPD/fetchSchedule";
import { TSchedule } from "../../types/schedule";
import { EventClickArg } from "@fullcalendar/core";
import { fDate } from "../../functions/dayJs";
import EditSchedule from "./components/EditSchedule";

function AppointmentSchedule() {
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;
  const calendarRef = useRef<FullCalendar | null>(null);
  const [schedules, setSchedules] = useState<TSchedule[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("timeGridWeek");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<IDoctor | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<TSchedule | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<TClinic>();

  const handleSelectedDoctor = (doctor: IDoctor) => setSelectedDoctor(doctor);

  const handleSelectedClinic = (clinic: TClinic) => setSelectedClinic(clinic);

  const onHandelSelectEventNull = () => setSelectedEvent(null);

  const onHandleDialog = (status: boolean) => {
    setOpen(status);
  };
  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView: string) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };
  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();

      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateSet = async (sDate: Date, eDate: Date) => {
    // set for change doctor and search again
    setStartDate(sDate);
    setEndDate(eDate);
    await handleFetchSchedules(selectedDoctor?._id, sDate, eDate);
  };

  const handleSelectedEvent = (arg: EventClickArg) => {
    const schedule = schedules.find(
      (schedule) => schedule._id === arg.event.id
    );
    if (schedule) {
      setSelectedEvent(schedule);
    }
  };

  const handleFetchSchedules = async (
    doctorId: string | undefined,
    startDate: Date | null,
    endDate: Date | null
  ) => {
    try {
      if (!doctorId || !startDate || !endDate || !selectedClinic?._id) return;
      const data = await fetchSchedules(
        idToken,
        doctorId,
        hospCode,
        selectedClinic._id,
        startDate,
        endDate,
        "SCHEDULE"
      );
      const events = data?.map((d) => ({
        ...d,
        title: `${d.dayOff ? "แพทย์ลาหยุด" : `จำนวน ${d.totalAppointment}`} `,
        id: d._id,
        backgroundColor: "blue",
      }));
      setSchedules(events);
    } catch (error) {
      console.log("fetch schedules error", error);
    }
  };

  const onSearch = async () => {
    await handleFetchSchedules(selectedDoctor?._id, startDate, endDate);
  };

  return (
    <div className="grid mt-20 mx-9 mb-6">
      <StyledCalendar>
        <Button
          sx={{ bgcolor: "white", my: 2, p: 2, borderRadius: 4 }}
          onClick={() => onHandleDialog(true)}
        >
          <AddCircle /> <label className="mx-2">สร้างตารางงานแพทย์</label>
        </Button>
        <Card
          className="px-6 py-6 overflow-scroll"
          sx={{ borderRadius: "20px" }}
        >
          <CalendarToolbar
            onChangeView={handleChangeView}
            onToday={handleClickToday}
            date={date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            hiddenChangeView={false}
            onSearch={onSearch}
            selectedHospCode={hospCode}
          >
            <MyHospFilter
              token={idToken}
              hospCode={hospCode}
              onSelectedDoctor={handleSelectedDoctor}
              onSelectedClinic={handleSelectedClinic}
            />
          </CalendarToolbar>
          <FullCalendar
            ref={calendarRef}
            height="auto"
            weekends
            editable
            droppable
            selectable
            rerenderDelay={10}
            dayMaxEventRows={3}
            allDayMaintainDuration
            eventResizableFromStart
            initialDate={date}
            initialView={view}
            events={schedules}
            eventClick={handleSelectedEvent}
            eventContent={(e) => (
              <div className="bg-blue-400 h-full text-justify w-full text-white top-0 bottom-0 ">
                <p>
                  {fDate(e.event.start, "HH:mm")} -{" "}
                  {fDate(e.event.end, "HH:mm")}
                </p>
                <p>{e.event.title}</p>
              </div>
            )}
            displayEventEnd
            headerToolbar={false}
            plugins={[listPlugin, dayGridPlugin, timeGridPlugin]}
            datesSet={(event) => {
              handleDateSet(event.start, event.end);
            }}
          />
        </Card>
      </StyledCalendar>
      <Dialog
        open={open}
        onClose={() => onHandleDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>schedule</DialogTitle>
        <DialogContent>
          <ScheduleForm />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onHandleDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      {selectedEvent && (
        <EditSchedule
          onSetEventNull={onHandelSelectEventNull}
          schedule={selectedEvent}
          token={idToken}
          onFetchSchedule={onSearch}
        />
      )}
    </div>
  );
}

export default AppointmentSchedule;
