import React, { FC } from "react";
import { Box, Hidden, IconButton, Tab, Tabs } from "@mui/material";
import {
  AirlineSeatFlat,
  DirectionsWalk,
  List,
  PrintOutlined,
} from "@mui/icons-material";
import { IDrug, IReferralPatientEMR, IVisit } from "@/types/visit";
import Opd from "./tab/Opd";
import Ipd from "./tab/Ipd";
import { IPatient } from "@/types/patient";
import { printReferral } from "../../../assets/pdf/printReferralRayong";
import { printReferralCBN } from "../../../assets/pdf/printReferralCBN";
import { ICustomerConfig } from "@/types/customerConfig";
interface IPatientVisit {
  visitAMB: IVisit;
  visitIMP: IVisit | null;
  value: number;
  onChangeTab(event: React.SyntheticEvent<Element, Event>, valueTab: any): void;
  patientFullName: { title: string; firstName: string; lastName: string };
  idToken: string;
  onRefresh(): void;
  patient: IPatient;
  customerConfig: ICustomerConfig;
  userHospCode: string;
  handleDialog(staus: boolean): void;
}
const index: FC<IPatientVisit> = ({
  visitAMB,
  visitIMP,
  value,
  onChangeTab,
  idToken,
  patientFullName,
  onRefresh,
  patient,
  customerConfig,
  userHospCode,
  handleDialog,
}) => {
  const otherItemsAMB = visitAMB.billingItems?.filter(
    (billItem: IDrug) => billItem.tmtCode === ""
  );
  const otherItemsIMP =
    visitIMP &&
    visitIMP.billingItems?.filter((billItem: IDrug) => billItem.tmtCode === "");

  const previewPdf = (
    visitAMB: IVisit | null,
    visitIMP: IVisit | null,
    patient: IPatient,
    referrals: IReferralPatientEMR[],
    appName: string | null
  ) => {
    if (!visitAMB || !appName) return;
    switch (appName) {
      case "ระบบแลกเปลี่ยนชลบุรี":
        printReferralCBN({
          patient,
          visitOPD: visitAMB,
          visitIPD: visitIMP,
          referrals,
          drugs: [...otherItemsAMB, ...visitAMB.drugs],
        });
        break;

      default:
        printReferral({
          patient,
          visit: visitAMB,
          referrals,
        });
        break;
    }
  };

  return (
    <>
      {(visitAMB || visitIMP) && (
        <Box className="flex flex-col w-full h-auto bg-white rounded-2xl border-solid border-gray border-2 my-4 px-2 ">
          <Box className="flex justify-end">
            <Hidden lgUp>
              <IconButton onClick={() => handleDialog(true)} color="primary">
                <List />
              </IconButton>
            </Hidden>
            {visitAMB &&
              !customerConfig?.disabledFeatures?.includes("pdfPrint") &&
              visitAMB.referrals.some(
                (refer) =>
                  refer.data.toHospCode === userHospCode ||
                  refer.data.fromHospCode === userHospCode
              ) && (
                <IconButton
                  color="primary"
                  onClick={() =>
                    previewPdf(
                      visitAMB,
                      visitIMP,
                      patient,
                      visitAMB.referrals,
                      customerConfig?.appName
                    )
                  }
                >
                  <PrintOutlined />
                </IconButton>
              )}
          </Box>

          <Box>
            <Tabs
              value={value}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                valueTab: any
              ) => onChangeTab(event, valueTab)}
              centered
              className="MuiTabs-centered-custom print:hidden"
            >
              {visitAMB && (
                <Tab
                  label="OPD"
                  className="text-blue-400"
                  icon={<DirectionsWalk />}
                />
              )}
              {visitIMP && (
                <Tab
                  label="IPD"
                  className="icon-button"
                  icon={<AirlineSeatFlat />}
                />
              )}
            </Tabs>

            {visitAMB && (
              <Opd
                value={value}
                hospName={visitAMB.hospName}
                departmentName={visitAMB.departmentName}
                spcltyName={visitAMB.spcltyName}
                vn={visitAMB.vn}
                pttype={visitAMB.pttype}
                pttypeName={visitAMB.pttypeName}
                hospmainName={visitAMB.hospmainName}
                hospsubName={visitAMB.hospsubName}
                doctorName={visitAMB.doctorName}
                hospmain={visitAMB.hospmain}
                hospsub={visitAMB.hospsub}
                visitDateTime={visitAMB.visitDateTime}
                referrals={visitAMB.referrals}
                dentals={visitAMB.dentals}
                doctorNotes={visitAMB.doctorNotes}
                visitNotes={visitAMB.note}
                appointments={visitAMB.appointments}
                visitVital={visitAMB.visitVital}
                visitVitals={visitAMB.visitVitals}
                xrays={visitAMB.xrays}
                drugs={visitAMB.drugs}
                otherItems={otherItemsAMB}
                labResults={visitAMB.labResults}
                diagnoses={visitAMB.diagnoses}
                idToken={idToken}
                patientFullName={patientFullName}
                onRefresh={onRefresh}
                customerConfig={customerConfig}
                hospCode={visitAMB.hospCode}
                hn={patient.hn}
                operations={visitAMB.operations}
              />
            )}
            {visitIMP && (
              <Ipd
                value={value}
                an={visitIMP.an}
                hospName={visitIMP.hospName}
                departmentName={visitIMP.departmentName}
                spcltyName={visitIMP.spcltyName}
                vn={visitIMP.vn}
                pttype={visitIMP.pttype}
                pttypeName={visitIMP.pttypeName}
                hospmainName={visitIMP.hospmainName}
                hospsubName={visitIMP.hospsubName}
                doctorName={visitIMP.doctorName}
                hospmain={visitIMP.hospmain}
                hospsub={visitIMP.hospsub}
                appointments={visitIMP.appointments}
                xrays={visitIMP.xrays}
                drugs={visitIMP.drugs}
                labResults={visitIMP.labResults}
                otherItems={otherItemsIMP}
                diagnoses={visitIMP.diagnoses}
                admission={visitIMP.admission}
                customerConfig={customerConfig}
                hospCode={visitIMP.hospCode}
                hn={patient.hn}
                operations={visitIMP.operations}
                regdate={visitIMP.regdate}
                regtime={visitIMP.regtime}
                dchdate={visitIMP.dchdate}
                dchtime={visitIMP.dchtime}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default index;
