import React, { useEffect, useMemo, useState } from "react";
import { Stack, FormControl, TextField } from "@mui/material";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { CalendarToday } from "@mui/icons-material";
import { v4 as randomUUID } from "uuid";
import { useForm } from "react-hook-form";
import DayArrayForm from "./DayArrayForm";
import TDepartment from "../../../types/department";
import { IDoctor, TClinic } from "../../../types/clinic";
import fetchDepartments from "../../../api/SettingAppointment/fetchDepartments";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import fetchClinics from "../../../api/SettingAppointment/fetchClinics";
import fetchDoctors from "../../../api/SettingAppointment/fetchDoctors";
import createSchedule from "../../../api/AppointmentOPD/createSchedule";
import Swal from "sweetalert2";
import "../css/AlertStyle.css";
import DropdownDoctor from "../../SettingAppointment/components/DropdownDoctor";
import DropdownClinic from "../../SettingAppointment/components/DropdownClinic";
import DropdownDepartment from "../../SettingAppointment/components/DropdownDepartment";

interface TDayForm {
  sTime: string;
  eTime: string;
  totalAppointment: string;
}
interface TDayFormBody extends TDayForm {
  dayId: string;
  roundId: string;
}

function ScheduleForm() {
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [selectedDepartment, setSelectDepartment] =
    useState<TDepartment | null>(null);
  const [clinics, setClinics] = useState<TClinic[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<TClinic | null>(null);
  const [doctors, setDoctors] = useState<IDoctor[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<IDoctor | null>(null);
  const defaultValues = useMemo(() => {
    const form = {
      department: 10,
      clinic: 10,
      doctor: 10,
      startDate: new Date(),
      endDate: new Date(),
      days: [
        {
          key: randomUUID(),
          name: "อาทิตย์",
          roundName: "sunRounds",
          schedules: [],
        },
        {
          key: randomUUID(),
          name: "จันทร์",
          roundName: "monRounds",
          schedules: [],
        },
        {
          key: randomUUID(),
          name: "อังคาร",
          roundName: "tueRounds",
          schedules: [],
        },
        {
          key: randomUUID(),
          name: "พุธ",
          roundName: "wedRounds",
          schedules: [],
        },
        {
          key: randomUUID(),
          name: "พฤหัสบดี",
          roundName: "thuRounds",
          schedules: [],
        },
        {
          key: randomUUID(),
          name: "ศุกร์",
          roundName: "friRounds",
          schedules: [],
        },
        {
          key: randomUUID(),
          name: "เสาร์",
          roundName: "satRounds",
          schedules: [],
        },
      ],
    };
    return form;
  }, []);

  const { control, register, handleSubmit, getValues, errors, setValue } =
    useForm({ defaultValues });

  const onFetchDepartments = async (token: string) => {
    try {
      const resp = await fetchDepartments(token);
      setDepartments(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };

  const onFetchClinics = async (
    token: string,
    departId: string,
    hospCode: string
  ) => {
    try {
      const resp = await fetchClinics(token, departId, hospCode);
      setClinics(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };

  const onFetchDoctors = async (token: string, doctorId: string[]) => {
    try {
      const resp = await fetchDoctors({ token, doctorId });
      setDoctors(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };

  const onSelectedDepartment = (department: TDepartment) => {
    if (!department || !department._id) return;
    setSelectDepartment(department);
    setSelectedClinic(null);
    setSelectedDoctor(null);
    onFetchClinics(idToken, department._id, department.hospCode);
  };

  const onSeletedClinic = (clinic: TClinic) => {
    setSelectedClinic(clinic);
    setSelectedDoctor(null);
    onFetchDoctors(idToken, clinic.doctorId);
  };

  const onSelectedDoctor = (doctor: IDoctor) => setSelectedDoctor(doctor);

  const onSubmit = async (data: any) => {
    const roundId = randomUUID();
    try {
      if (
        !dateRange[0] ||
        !dateRange[1] ||
        !selectedDepartment ||
        !selectedClinic ||
        !selectedDoctor
      ) {
        throw Error("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
      const [sDate, eDate] = dateRange;
      const body: any = {
        hospCode,
        depertId: selectedDepartment?._id,
        clinicId: selectedClinic?._id,
        doctorId: selectedDoctor?._id,
        startDate: new Date(sDate).toISOString(),
        endDate: new Date(eDate).toISOString(),
        sunRounds: [],
        monRounds: [],
        tueRounds: [],
        wedRounds: [],
        thuRounds: [],
        friRounds: [],
        satRounds: [],
      };

      // for map schedule format
      for (let index = 0; index < data.days.length; index++) {
        const day = data.days[index];
        if (day && day.schedules) {
          const defaultDay = defaultValues.days[index];
          const schedules: TDayFormBody[] = day.schedules?.map(
            (schedule: TDayForm) => ({
              ...schedule,
              dayId: defaultDay.key,
              roundId,
              timeId: randomUUID(),
            })
          );
          body[defaultDay.roundName].push(...schedules);
        }
      }

      await createSchedule(idToken, body);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "สร้างตารางนัดหมายเรียบร้อย",
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error: any) {
      console.log("form error", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "สร้างตารางนัดหมายล้มเหลว" + error?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    onFetchDepartments(idToken);
  }, [idToken]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Stack
          direction="column"
          alignItems="center"
          className="bg-white"
          spacing={2}
          padding={5}
        >
          <FormControl className="bg-white rounded-2xl w-96">
            <DropdownDepartment
              departments={departments}
              onSelectedDepartment={onSelectedDepartment}
            />
          </FormControl>
          <FormControl className="bg-white rounded-2xl w-96">
            <DropdownClinic
              clinics={clinics}
              handleSeletedClinic={onSeletedClinic}
            />
          </FormControl>
          <FormControl className="bg-white rounded-2xl w-96">
            <DropdownDoctor
              doctors={doctors}
              onSelectedDoctor={onSelectedDoctor}
            />
          </FormControl>
          <FormControl className="bg-white rounded-2xl w-full flex justify-between">
            <DateRangePicker
              value={dateRange}
              disablePast
              inputFormat="DD/MM/YYYY"
              onChange={(newDateRange) => {
                setDateRange(newDateRange);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    InputProps={{
                      startAdornment: (
                        <CalendarToday className="text-gray-500" />
                      ),
                      sx: { borderRadius: 6, bgcolor: "white" },
                    }}
                  />
                  <TextField
                    {...endProps}
                    InputProps={{
                      endAdornment: <CalendarToday className="text-gray-500" />,
                      sx: { borderRadius: 6, bgcolor: "white" },
                    }}
                  />
                </>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <DayArrayForm
        {...{ control, register, defaultValues, getValues, setValue, errors }}
      />
      <input
        type="submit"
        className=" justify-center bg-blue-400 w-full text-white my-4 h-12 rounded-lg"
      />
    </form>
  );
}

export default ScheduleForm;
