import React from "react";
import {
  IAppointment,
  IDental,
  IDiagnosis,
  IDoctorNote,
  IDrug,
  ILabResult,
  IOperation,
  IReferralPatientEMR,
  IVisitVital,
  IXrays,
} from "@/types/visit";
import TabPanel from "../../../Utility/TabPanel";
import VisitDetail from "../../visit/VisitDetail";
import VisitReferrals from "../../VisitsReferrals/VisitReferrals";
import Appointment from "../Appointment/Appointment";
import Dental from "../Dental/Dental";
import Diagnosis from "../Diagnosis/Diagnosis";
import DoctorNote from "../DoctorNote/DoctorNote";
import Drugs from "../Drug/Drugs";
import LabResult from "../LabResult/LabResult";
import PhysicalExam from "../PhysicalExam/PhysicalExam";
import Xrays from "../Xray/Xrays";
import Screen from "../Screen/Screen";
import { Box, Typography } from "@mui/material";
import { ICustomerConfig } from "@/types/customerConfig";
import Operations from "../Operation/Operations";
import VisitNote from "../../visit/VisitNote";
import { ThaiDateTime } from "../../../../functions/FuncDateTimes";

interface IOpd {
  value: number;
  hospName: string;
  departmentName: string;
  spcltyName: string;
  vn: string;
  pttype: string;
  pttypeName: string;
  hospmainName: string;
  hospsubName: string;
  doctorName: string;
  hospmain: string;
  hospsub: string;
  visitDateTime: string;
  referrals: IReferralPatientEMR[];
  doctorNotes: IDoctorNote[];
  visitNotes?: string;
  appointments: IAppointment[];
  visitVital: IVisitVital;
  visitVitals: IVisitVital[];
  dentals: IDental[];
  xrays: IXrays[];
  drugs: IDrug[];
  otherItems: IDrug[];
  labResults: ILabResult[];
  diagnoses: IDiagnosis[];
  operations: IOperation[];
  patientFullName: { title: string; firstName: string; lastName: string };
  idToken: string;
  onRefresh(): void;
  customerConfig: ICustomerConfig;
  hn: { hospCode: string; value: string }[];
  hospCode: string;
}
function Opd({
  hospName,
  departmentName,
  spcltyName,
  vn,
  pttype,
  pttypeName,
  hospmainName,
  hospsubName,
  doctorName,
  hospmain,
  hospsub,
  visitDateTime,
  referrals,
  dentals,
  doctorNotes,
  visitNotes,
  appointments,
  visitVital,
  visitVitals,
  xrays,
  drugs,
  otherItems,
  labResults,
  diagnoses,
  value,
  idToken,
  patientFullName,
  onRefresh,
  customerConfig,
  hn,
  hospCode,
  operations,
}: IOpd) {
  const vDateTime = ThaiDateTime(visitDateTime);

  return (
    <TabPanel value={value} index={0}>
      <Box
        component="div"
        className="flex flex-col w-full h-auto bg-white rounded-2xl border-solid border-gray border-2 my-4 px-2 "
      >
        <Box className="border-b-2 border-gray-200 px-3 py-3">
          <Typography color="#58595B">{`วันที่ตรวจ : ${vDateTime}`}</Typography>
        </Box>
        <VisitDetail
          hospName={hospName}
          departmentName={departmentName}
          spcltyName={spcltyName}
          vn={vn}
          pttype={pttype}
          pttypeName={pttypeName}
          hospmainName={hospmainName}
          hospsubName={hospsubName}
          doctorName={doctorName}
          hospmain={hospmain}
          hospsub={hospsub}
          hn={hn}
          hospCode={hospCode}
        />
        {referrals?.length > 0 && (
          <VisitReferrals
            referrals={referrals}
            idToken={idToken}
            patientFullName={patientFullName}
            onRefresh={onRefresh}
          />
        )}
        {operations?.length > 0 && <Operations operations={operations} />}
        {dentals?.length > 0 && <Dental dentals={dentals} />}
        {doctorNotes?.length > 0 && <DoctorNote doctorNotes={doctorNotes} />}
        {visitNotes && <VisitNote visitNotes={visitNotes} />}
        {appointments?.length > 0 && (
          <Appointment appointments={appointments} />
        )}
        {Object.keys(visitVital).length > 0 && <Screen {...visitVital} />}
        {visitVitals?.length > 0 && visitVitals.map((v) => <Screen {...v} />)}
        {Object.keys(visitVital).length > 0 && <PhysicalExam {...visitVital} />}
        {visitVitals?.length > 0 &&
          visitVitals.map((v) => <PhysicalExam {...v} />)}
        {diagnoses?.length > 0 && <Diagnosis diagnosis={diagnoses} />}
        {xrays?.length > 0 && <Xrays xrays={xrays} />}
        {drugs?.length > 0 && (
          <Drugs
            drugs={drugs}
            title="Drug Items"
            customerConfig={customerConfig}
          />
        )}
        {otherItems?.length > 0 && (
          <Drugs
            drugs={otherItems}
            title="Other Items"
            customerConfig={customerConfig}
          />
        )}
        {labResults?.length > 0 && <LabResult labResults={labResults} />}
      </Box>
    </TabPanel>
  );
}

export default Opd;
