import { RootState } from "../../reducers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MyHospFilter from "./components/MyHospFilter";
import { Button, TextField } from "@mui/material";
import { IDoctor, TClinic } from "@/types/clinic";
import { Search } from "@mui/icons-material";
import { IReferral } from "../../types/refers";
import ReferCard from "../Refers/ReferCard";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/Users/servicePermission";
import { Ability } from "@casl/ability";
import Swal from "sweetalert2";
// api
import fetchSchedules from "../../api/AppointmentOPD/fetchSchedule";
import RefersReject from "../../api/RefersReject";
import RefersAccept from "../../api/RefersAccept";
// import TabContext from "@mui/lab/TabContext";
// import TabPanel from "@mui/lab/TabPanel";
// import TabList from "@mui/lab/TabList";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";

function HospitalAppointment() {
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
    permissionRules,
  } = appData;
  // const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  // const [endDate, setEndDate] = useState<Date>(
  //   new Date(new Date().setDate(selectedDate.getDate() + 7))
  // );
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);
  const [selectedDoctor, setSelectedDoctor] = useState<IDoctor | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<TClinic>();
  // const [todayRefers, setTodayRefers] = useState<IReferral[]>([]);
  const [refers, setRefers] = useState<IReferral[]>([]);
  const [ability, setAbility] = useState<any>();
  // const [tabValue, setTabValue] = useState<string>("today");

  // const handleChangeDate = (date: Date) => {
  //   const nDate = new Date(date);
  //   setSelectedDate(nDate);
  //   const endDate = new Date();
  //   endDate.setDate(nDate.getDate() + 7);
  //   setEndDate(endDate);
  // };
  const handleSelectedClinic = (clinic: TClinic) => setSelectedClinic(clinic);
  const handleSelectedDoctor = (doctor: IDoctor) => setSelectedDoctor(doctor);

  const handleFetchSchedules = async (
    doctorId: string | undefined,
    hospCode: string
  ) => {
    try {
      const [start, end] = dateRange;
      if (!doctorId || !hospCode || !selectedClinic?._id || !start || !end) {
        return;
      }

      // const start = new Date(dateRange[0]);
      // const end = new Date(dateRange[1]);
      const data = await fetchSchedules(
        idToken,
        doctorId,
        hospCode,
        selectedClinic._id,
        new Date(start),
        new Date(end),
        "VERIFY"
      );
      const refers = data
        .filter((appointment) => appointment.refersAppointment.length > 0)
        .map((appointment) => appointment.refersAppointment)
        .flat();
      // set for filter refer

      // const referTodays = refers.filter((refer) => {
      //   return new Date(refer.appointmentDateTime) < new Date();
      // });
      // const referComing = refers.filter(
      //   (refer) => new Date(refer.appointmentDateTime) > new Date(start)
      // );
      // setTodayRefers(referTodays);
      setRefers(refers);
    } catch (error) {
      console.log("fetch schedules error", error);
    }
  };
  const onAcceptAppointment = async (
    referral: IReferral,
    appointmentPoint: string,
    appointmentNotes: string,
    appointmentDateTime: string
  ) => {
    try {
      if (!referral) throw Error("selected appointment");
      const dataRequnest = {
        idToken,
        bid: referral.bid,
        appointmentDateTime,
        appointmentPoint,
        appointmentNotes,
      };
      await RefersAccept(dataRequnest);
      Swal.fire("Saved!", "", "success");
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const onRejectAppointment = async (referral: IReferral, reason: string) => {
    try {
      if (!referral) throw Error("selected appointment");
      const dataRequnest = {
        idToken,
        bid: referral.bid,
        reason,
      };
      await RefersReject(dataRequnest);
      Swal.fire("Saved!", "", "success");
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  useEffect(() => setAbility(new Ability(permissionRules)), [permissionRules]);

  const refersCardItem = (refers: IReferral[]) => {
    return refers.map((refer, index) => (
      <ReferCard
        referral={refer}
        cardIndex={index}
        key={index}
        changeRefer={ability.can(
          ServicePermissionEvent.UPDATE,
          ServicePermissionService.CHANGE_REFER_STATUS
        )}
        actionRefer={ability.can(
          ServicePermissionEvent.UPDATE,
          ServicePermissionService.REFERRAL
        )}
        onAcceptRefer={(appointmentInfo: {
          appointmentPoint: string;
          appointmentNotes: string;
          appointmentDateTime: string;
        }) => {
          onAcceptAppointment(
            refer,
            appointmentInfo.appointmentPoint,
            appointmentInfo.appointmentNotes,
            appointmentInfo.appointmentDateTime
          );
        }}
        onRejectRefer={(reason: string) => {
          onRejectAppointment(refer, reason);
        }}
      />
    ));
  };
  return (
    <div className="grid mt-14">
      <div className="grid grid-cols-2 gap-4 bg-white p-6">
        <div>
          <MyHospFilter
            token={idToken}
            hospCode={hospCode}
            onSelectedDoctor={handleSelectedDoctor}
            onSelectedClinic={handleSelectedClinic}
          />
        </div>
        <div className="flex">
          <DateRangePicker
            value={dateRange}
            inputFormat="DD/MM/YYYY"
            onChange={(date) => setDateRange(date)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField id="start-date" {...startProps} />
                <TextField
                  {...endProps}
                  id="end-date"
                  InputProps={{
                    endAdornment: (
                      <CalendarTodayIcon className="text-gray-500" />
                    ),
                  }}
                />
              </>
            )}
          />
          <Button
            variant="contained"
            onClick={() => handleFetchSchedules(selectedDoctor?._id, hospCode)}
            disabled={!selectedDoctor}
            sx={{
              bgcolor: "white",
              color: "gray",
              borderRadius: "20px",
              mx: 2,
              p: 2,
              ":hover": {
                backgroundColor: "#fff",
                color: "#132043",
                border: "0.5px solid",
              },
            }}
          >
            <Search />
          </Button>
        </div>
      </div>

      <div
        className="grid gap-4 p-4 bg-gray-100
            md:grid-cols-2
            lg:grid-cols-3"
      >
        {refersCardItem(refers)}
      </div>
    </div>
  );
}

export default HospitalAppointment;
