import React from 'react'
import { useParams } from 'react-router-dom';
import reportJson from "./report.json";

type TParams = {
  docId: string;
};
function ReportDetail() {
  const { docId } = useParams<TParams>();
  const report = reportJson.find((report) => report.id === docId);
  return (
    <div className="flex justify-center mt-20 h-full">
    <iframe
      title={report?.title}
      width="80%"
      height="100%"
      src={report?.detail}
      allowFullScreen
    />
  </div>
  )
}

export default ReportDetail