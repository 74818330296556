import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Chip,
  FormControlLabel,
  CircularProgress,
  Grid,
  RadioGroup,
  Radio,
  Typography,
  Autocomplete,
  Divider,
  Box,
  Switch,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../Utility/TabPanel";
import ListboxComponent from "../Utility/ListBoxComponents";
import editUser from "../../actions/UserManagement/editUser.action";
import addUser from "../../actions/UserManagement/addUser.action";
import fetchHospitalListForUserManagement from "../../actions/UserManagement/fetchHospitalListForUserManagement.action";
import fetchDistinctRoles from "../../actions/PermissionManagement/fetchDistinctRoles.action";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/Users/servicePermission";
import { RootState } from "../../reducers";
import { IUser } from "@/types/user";
import { useTranslation } from "react-i18next";
import TownImage from "../../assets/images/townImage.svg";

interface IUserFormData {
  username: string;
  password?: string;
  email: string;
  roleId: string;
  firstName: string;
  lastName: string;
  identityType: "citizenship" | "empCode";
  citizenship: string;
  empCode: string;
}

interface IPasswordFormData {
  newPassword?: string;
  confirmationPassword?: string;
  currentPassword?: string;
}

export default function UserForm() {
  const { t } = useTranslation("common");

  const history = useHistory<{ user?: IUser }>();
  const user = history.location.state?.user;

  const dispatch = useDispatch();

  const { control, watch, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedHospital, setSelectedHospital] =
    useState<{ hospCode: string; hospName: string }>();
  const [members, setMembers] = useState<{ key: string; hospCode: string }[]>(
    []
  );
  const [tab, setTab] = useState(0);
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(
    user?.security?.twoFactor?.enabled || false
  );

  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    hospitalList,
    alert,
    permissionRules,
    permissionManagement,
  } = appData;

  function ActionBtn() {
    return (
      <div className="flex justify-end mt-2">
        <Button
          disabled={loading}
          className="!mr-2 rounded !text-sky-500"
          variant="outlined"
          onClick={() => history.push("/user-management")}
        >
          ยกเลิก
        </Button>
        <Button
          disabled={loading}
          type="submit"
          className="rounded !bg-sky-500 text-white"
          variant="contained"
        >
          ยืนยัน
        </Button>
      </div>
    );
  }

  const onSubmit = async (userFormData: IUserFormData) => {
    setLoading(true);

    if (user) {
      const body = {
        username: userFormData.username,
        email: user.email !== userFormData.email ? userFormData.email : "",
        hospCode:
          user.hospCode !== selectedHospital?.hospCode
            ? selectedHospital?.hospCode
            : "",
        roleId: user.roleId !== userFormData.roleId ? userFormData.roleId : "",
        firstName:
          user.fullName.firstName !== userFormData.firstName
            ? userFormData.firstName
            : "",
        lastName:
          user.fullName.lastName !== userFormData.lastName
            ? userFormData.lastName
            : "",
        citizenship: userFormData.citizenship,
        empCode: userFormData.empCode,
        members: members.map((item) => item?.hospCode),
        isTwoFactorEnabled,
      };

      const response = (await dispatch(editUser({ idToken, body }))) as any;
      if (response.value.status !== 200) {
        Swal.fire({
          title: "แก้ไขข้อมูลไม่สำเร็จ",
          text: response.value.error,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "แก้ไขข้อมูลเรียบร้อย",
          icon: "success",
        });
      }

      setLoading(false);
    } else {
      const randomPassword = Math.random().toString(36).slice(-8);

      const body = {
        username: userFormData.username,
        password: randomPassword,
        email: userFormData.email,
        hospCode: selectedHospital?.hospCode,
        roleId: userFormData.roleId,
        firstName: userFormData.firstName,
        lastName: userFormData.lastName,
        citizenship: userFormData.citizenship,
        empCode: userFormData.empCode,
        members: members.map((item) => item?.hospCode),
      };

      const response = (await dispatch(addUser({ idToken, body }))) as any;
      if (response.value.status !== 200) {
        Swal.fire({
          title: "สร้างบัญชีไม่สำเร็จ",
          text: response.value.error,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "สร้างบัญชีใหม่เรียบร้อย",
          text: "โปรดตรวจสอบอีเมล์ เพื่อรับรหัสผ่าน",
          icon: "success",
        });

        history.push("/user-management");
      }

      setLoading(false);
    }
  };

  const onUpdatePassword = async (passwordFormData: IPasswordFormData) => {
    if (
      passwordFormData.newPassword !== passwordFormData.confirmationPassword ||
      !passwordFormData.newPassword ||
      !passwordFormData.confirmationPassword ||
      !passwordFormData.currentPassword
    ) {
      return;
    }

    setLoading(true);

    const body = {
      username: user?.username,
      password: passwordFormData.newPassword,
      currentPassword: passwordFormData.currentPassword,
    };

    const response = (await dispatch(editUser({ idToken, body }))) as any;
    if (response.value.status !== 200) {
      Swal.fire({
        title: "แก้ไขข้อมูลไม่สำเร็จ",
        text: response.value.error,
        icon: "error",
      });
    } else {
      Swal.fire({
        title: "แก้ไขข้อมูลเรียบร้อย",
        icon: "success",
      });
    }

    setLoading(false);
  };

  const handleDeleteHospCodeList = (chipToDelete: { key: string }) => {
    setMembers((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const defaultValueForCitizenOrEmpCode = () => {
    if (user?.citizenship && user?.empCode) {
      return "citizenship";
    }

    if (user?.empCode) {
      return "empCode";
    }

    return "citizenship";
  };

  const handleTwoFactorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsTwoFactorEnabled(event.target.checked);
  };

  useEffect(() => {
    dispatch(fetchHospitalListForUserManagement({ idToken, limit: 1000 }));
  }, []);

  useEffect(() => {
    const tmpMembers: { key: string; hospCode: string }[] = [];
    user?.members?.map((item) =>
      tmpMembers.push({ key: uuid(), hospCode: item })
    );

    setMembers(tmpMembers);

    if (user) {
      setSelectedHospital({
        hospCode: user.hospCode,
        hospName: hospitalList?.docs?.find(
          (hospital: { hospCode: string; hospName: string }) =>
            hospital.hospCode === user?.hospCode
        )?.hospName,
      });
    }
  }, [user]);

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  useEffect(() => {
    if (selectedHospital) {
      dispatch(
        fetchDistinctRoles({ idToken, hospCode: selectedHospital.hospCode })
      );
    }
  }, [selectedHospital]);

  const SecuritySettings = () => {
    if (!user) {
      return null;
    }

    return (
      <>
        <Divider sx={{ marginY: 6 }} />

        <Typography variant="h6">ตั้งค่าความปลอดภัยของบัญชี</Typography>

        <Typography variant="caption" fontWeight="light" marginBottom={2}>
          ** เพื่อความปลอดภัยมากยิ่งขึ้น
          ขอแนะนำให้ผู้ใช้เปิดใช้งานการยืนยันตัวตน 2
          ขั้นตอนทางอีเมล์สำหรับบัญชีของท่าน
        </Typography>

        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Typography variant="body2">
            การยืนยันตัวตน 2 ขั้นตอนผ่านทาง Email
          </Typography>

          <Switch
            disabled={!user.email}
            checked={isTwoFactorEnabled}
            onChange={handleTwoFactorChange}
          />
        </Box>
      </>
    );
  };

  return (
    <Box display={"flex"} justifyContent={"center"} paddingX={10}>
      <Box width={"100%"} className="mt-24 bg-white rounded-xl">
        <Grid container padding={2}>
          <Grid item xs>
            <Typography variant="h5">
              {user ? "แก้ไขผู้ใช้งาน" : "ผู้ใช้งานใหม่"}
            </Typography>
          </Grid>
          {user && (
            <Grid item xs="auto">
              <Tabs value={tab} onChange={(e, idx) => setTab(idx)}>
                <Tab label="Profile" />
                {appData.loginData.username === user.username && (
                  <Tab label="Password" />
                )}
              </Tabs>
            </Grid>
          )}
        </Grid>

        {/* SECTION: profile tab */}
        <TabPanel value={tab} index={0}>
          <form
            className="flex flex-col gap-y-1"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={{ md: 6, sm: 0 }}>
              <Grid
                container
                item
                display={"flex"}
                flexDirection={"column"}
                xs={12}
                md={6}
              >
                <Grid item>
                  <Grid
                    item
                    xs
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Typography variant="h6" marginBottom={2}>
                      ข้อมูลส่วนตัว
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          as={
                            <TextField
                              required
                              fullWidth
                              size="small"
                              label="ชื่อผู้ใช้งาน"
                              variant="outlined"
                              disabled={loading || !!user}
                            />
                          }
                          control={control}
                          name="username"
                          defaultValue={user?.username || ""}
                        />
                      </Grid>
                      <Grid item xs>
                        <Controller
                          as={
                            <TextField
                              required
                              fullWidth
                              size="small"
                              label="อีเมล์"
                              variant="outlined"
                              disabled={loading || !!user?.email}
                              type="email"
                            />
                          }
                          control={control}
                          name="email"
                          defaultValue={user?.email || ""}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} className="pt-2">
                      <Grid item xs={12} md={6}>
                        <Controller
                          as={
                            <TextField
                              fullWidth
                              required
                              size="small"
                              label="ชื่อ"
                              variant="outlined"
                            />
                          }
                          control={control}
                          name="firstName"
                          defaultValue={user?.fullName?.firstName || ""}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          as={
                            <TextField
                              fullWidth
                              required
                              size="small"
                              label="นามสกุล"
                              variant="outlined"
                            />
                          }
                          control={control}
                          name="lastName"
                          defaultValue={user?.fullName?.lastName || ""}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    item
                    xs
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <SecuritySettings />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Typography variant="h6" marginBottom={2}>
                  ข้อมูลบัญชี
                </Typography>

                {hospitalList?.docs?.[0] && (
                  <Autocomplete
                    disableListWrap
                    loading={!hospitalList?.docs[0]}
                    ListboxComponent={ListboxComponent}
                    options={hospitalList?.docs}
                    defaultValue={
                      user && {
                        hospCode: user?.hospCode,
                        hospName: hospitalList?.docs?.find(
                          (hospital: { hospCode: string; hospName: string }) =>
                            hospital.hospCode === user?.hospCode
                        )?.hospName,
                      }
                    }
                    onChange={(option, value) =>
                      value && setSelectedHospital(value)
                    }
                    getOptionLabel={(option) =>
                      `${option.hospCode} : ${option.hospName}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        size="small"
                        label="โรงพยาบาล"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {!hospitalList?.docs[0] ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}

                {/* only user who has permission to access permission management can set role for another user */}
                {ability &&
                  ability.can(
                    ServicePermissionEvent.READ,
                    ServicePermissionService.MANAGEMENT.PERMISSION_MANAGEMENT
                  ) &&
                  permissionManagement.distinctRoles && (
                    <Controller
                      as={
                        <TextField
                          select
                          size="small"
                          label="สิทธิ์"
                          variant="outlined"
                          disabled={loading}
                        />
                      }
                      control={control}
                      name="roleId"
                      defaultValue={
                        user?.roleId || permissionManagement.distinctRoles[0]
                      }
                    >
                      {permissionManagement.distinctRoles.map(
                        (
                          roles: { _id: string; role: string },
                          index: number
                        ) => (
                          <MenuItem key={index} value={roles._id}>
                            {roles.role}
                          </MenuItem>
                        )
                      )}
                    </Controller>
                  )}

                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Controller
                    as={<RadioGroup row />}
                    control={control}
                    name="identityType"
                    defaultValue={defaultValueForCitizenOrEmpCode()}
                  >
                    <FormControlLabel
                      value="citizenship"
                      control={<Radio color="primary" />}
                      label="รหัสบัตรประชาชน"
                    />
                    <FormControlLabel
                      value="empCode"
                      control={<Radio color="primary" />}
                      label="รหัสพนักงาน"
                    />
                  </Controller>
                </Box>

                <Controller
                  as={
                    <TextField
                      required={
                        watch("identityType", () => {
                          if (user?.citizenship && user?.empCode) {
                            return "citizenship";
                          }

                          if (user?.empCode) {
                            return "empCode";
                          }

                          return "citizenship";
                        }) === "citizenship"
                      }
                      size="small"
                      label="รหัสบัตรประชาชน"
                      variant="outlined"
                      type="number"
                      autoComplete="off"
                      className={`${
                        watch("identityType") !== "citizenship" && "!hidden"
                      }`}
                    />
                  }
                  control={control}
                  name="citizenship"
                  defaultValue={user?.citizenship || ""}
                  rules={{ maxLength: 13, minLength: 13 }}
                />

                {(errors.citizenship?.type === "maxLength" ||
                  errors.citizenship?.type === "minLength") && (
                  <Typography color="error">
                    กรุณาระบุรหัสบัตรประชาชน 13 หลักให้ถูกต้อง
                  </Typography>
                )}

                <Controller
                  as={
                    <TextField
                      required={
                        watch(
                          "identityType",
                          defaultValueForCitizenOrEmpCode()
                        ) === "empCode"
                      }
                      size="small"
                      label="รหัสพนักงาน"
                      variant="outlined"
                      type="number"
                      className={`${
                        watch("identityType") !== "empCode" && "!hidden"
                      }`}
                    />
                  }
                  control={control}
                  name="empCode"
                  defaultValue={user?.empCode || ""}
                />

                {ability &&
                  ability.can(
                    ServicePermissionEvent.CREATE,
                    ServicePermissionService.MANAGEMENT.USER_MANAGEMENT
                      .USER_MEMBER
                  ) && (
                    <>
                      <Box className="text-left">
                        {members?.map((item) => (
                          <Chip
                            label={item.hospCode}
                            color="secondary"
                            variant="outlined"
                            onDelete={() => handleDeleteHospCodeList(item)}
                            className="!mr-2 !mt-2"
                          />
                        ))}
                      </Box>

                      <Autocomplete
                        onChange={(option, value) =>
                          value &&
                          setMembers([
                            ...members,
                            { key: uuid(), hospCode: value.hospCode },
                          ])
                        }
                        ListboxComponent={ListboxComponent}
                        options={hospitalList?.docs?.filter(
                          (hospital: { hospCode: string; hospName: string }) =>
                            hospital?.hospCode !== selectedHospital?.hospCode
                        )}
                        getOptionLabel={(option: {
                          hospCode: string;
                          hospName: string;
                        }) => `${option?.hospCode} : ${option?.hospName}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="รายการ รพ. ที่ดูแล"
                          />
                        )}
                      />
                    </>
                  )}
              </Grid>
            </Grid>

            <div className="alert-container">
              {alert.show && <Alert severity={alert.type}>{alert.msg}</Alert>}
            </div>

            <ActionBtn />
          </form>
        </TabPanel>

        {/* SECTION: password tab */}
        <TabPanel value={tab} index={1}>
          <Grid
            container
            spacing={{ md: 6, sm: 0 }}
            width={{ xs: "100%" }}
            marginLeft={{ xs: 0 }}
          >
            <Grid
              item
              md={6}
              sx={{
                backgroundImage: `url(${TownImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "487px",
                width: "100%",
                marginTop: 4,
                display: { xs: "none", md: "flex" },
              }}
            />
            <Grid item xs={12} md={6}>
              <form
                className="flex flex-col gap-y-2 h-full"
                onSubmit={handleSubmit(onUpdatePassword)}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={"100%"}
                >
                  <Box className="flex flex-col gap-y-2">
                    <Controller
                      as={
                        <TextField
                          required
                          size="small"
                          label="รหัสผ่านปัจจุบัน"
                          variant="outlined"
                          disabled={loading}
                          type="password"
                        />
                      }
                      control={control}
                      name="currentPassword"
                    />

                    <Controller
                      as={
                        <TextField
                          required
                          size="small"
                          label="รหัสผ่านใหม่"
                          variant="outlined"
                          disabled={loading}
                          type="password"
                        />
                      }
                      control={control}
                      name="newPassword"
                      rules={{
                        minLength: 6,
                      }}
                    />

                    <Controller
                      as={
                        <TextField
                          required
                          size="small"
                          label="ยืนยันรหัสผ่าน"
                          variant="outlined"
                          disabled={loading}
                          type="password"
                        />
                      }
                      control={control}
                      name="confirmationPassword"
                    />

                    {watch("newPassword", "") &&
                      watch("confirmationPassword", "") &&
                      (watch("confirmationPassword", "") !==
                        watch("newPassword", "") ||
                        watch("newPassword", "")?.length < 6 ||
                        watch("confirmationPassword", "")?.length < 6) && (
                        <Typography color="error">
                          {t("welcome.password_mismatch")}
                        </Typography>
                      )}
                  </Box>
                  <Box>
                    <ActionBtn />
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </TabPanel>

        {loading && <LinearProgress />}
      </Box>
    </Box>
  );
}
