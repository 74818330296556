import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TSchedule } from "../../types/schedule";

const updateSchedule = async (
  token: string,
  data: TSchedule,
  timeId: string | undefined,
  roundId: string | undefined
) => {
  const url = getApiUrl();
  await axios.put(
    `${url}/v1/schedule/${data._id}`,
    { data, timeId, roundId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export default updateSchedule;
