import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavigationMain from "../components/NavigationMain/NavigationMain";
import ContainerMain from "../components/ContainerMain";
import logout from "../actions/User/logout.action";

function Index() {
  const dispatch = useDispatch();
  const { appData } = useSelector((state) => state);
  const { loginData } = appData;
  const expireDate = localStorage.getItem("expireDate");
  const checkExpire = new Date() >= new Date(expireDate);

  function logoutApp(previousUser) {
    const payload = {
      loginData: null,
      loginStatus: false,
      idToken: "",
      FetchingStatus: false,
      previousUser,
    };

    localStorage.removeItem("expireDate");
    localStorage.setItem("Token", "");

    dispatch(logout(payload));
  }

  useEffect(() => {
    if (!expireDate || checkExpire) {
      logoutApp(loginData?.username);
    }
  }, [checkExpire, loginData]);

  return (
    <div className="bg-gray-100">
      <Route render={(props) => <NavigationMain {...props} />} />
      <Route render={(props) => <ContainerMain {...props} />} />
    </div>
  );
}

export default withRouter(Index);
