import axios from "axios";
import { getApiUrl } from "../functions/Commons";
import { IHistoryRefer } from "@/types/historyRefer";

export const getHistoryReferId = async (
  token: string,
  referBid: string
): Promise<IHistoryRefer[]> => {
  try {
    const url = getApiUrl();

    const response = await axios.get(`${url}/v1/history/refer/${referBid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
