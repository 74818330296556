import { Box, Typography } from "@mui/material";
import React from "react";
import { dateToLocalDateString } from "../../../functions/FuncDateTimes";
import { IReferral } from "../../../types/refers";
import { PENDING } from "../../../constants/Refers/referralStatus";
import { shortHospName } from "../../../functions/FuncPerjer";
import dayjs from "dayjs";

type TReferDetail = {
  referral: IReferral;
};
function ReferDetail({ referral }: TReferDetail) {
  const checkStatusRefer =
    referral.status === PENDING && referral.data.incomplete;

  const expireDate = () => {
    if (referral.data.expireDateTime) {
      return dateToLocalDateString(referral.data.expireDateTime?.toString());
    }
    if (referral.data.referDateTime) {
      const dateOfDayjs = dayjs(referral.data.referDateTime).add(3, "months");
      return `${dateToLocalDateString(dateOfDayjs?.toString())} (อัตโนมัติ)`;
    }
    return "-";
  };

  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box className="flex justify-between border-b-2 border-gray-200 h-10 px-5 py-1 items-center">
        <Typography
          variant="h6"
          color="#58595B"
        >{`Referral: ${referral.data.referNumber}`}</Typography>
      </Box>
      <Box className="px-6 py-3">
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {checkStatusRefer
              ? "สถานะ : ข้อมูลไม่ครบถ้วน"
              : `สถานะ : ${referral.status}`}
          </Typography>
        </Box>
        {referral.rejectReason && (
          <Box>
            <Typography variant="inherit" className="text-slate-700">
              {`เหตุผลการปฏิเสธ: ${referral.rejectReason}`}
            </Typography>
          </Box>
        )}

        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`วันที่ส่งตัว : ${dateToLocalDateString(
              referral.data.referDateTime
            )}`}
          </Typography>
          <Typography
            variant="inherit"
            className="text-slate-700"
          >{`วันหมดอายุ : ${expireDate()}`}</Typography>
        </Box>
        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`รพ.ต้นทาง : ${referral.data.fromHospCode} ${shortHospName(
              referral.fromHospName
            )}`}
          </Typography>
          <Typography variant="inherit" className="text-slate-700">
            {`รพ.ปลายทาง : ${referral.data.toHospCode} ${shortHospName(
              referral.toHospName
            )}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {`Refer Type : ${referral.type}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {`สิทธิการรักษา : ${referral.data.pttypeName}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {`ICD10 : ${referral.data.icd} - ${referral.data.icdName}`}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="inherit"
            className="text-slate-700"
          >{`Diagnosis : ${referral.data.preDiagnosis}`}</Typography>
        </Box>
        <Box>
          <Typography
            variant="inherit"
            className="text-slate-700"
          >{`เหตุผลการส่งต่อ : ${referral.data.reason}`}</Typography>
        </Box>
        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`ประเภทผู้ป่วย : ${referral.data.referoutTypeName || "-"}`}
          </Typography>
          <Typography variant="inherit" className="text-slate-700">
            {`ความเร่งด่วน : ${referral.data.referoutEmergencyTypeName || "-"}`}
          </Typography>
        </Box>

        {referral.appointmentDateTime && (
          <Box className="border-2 border-gray-200 my-2 rounded-md">
            <Box className="border-b-2 border-gray-100">
              <Typography variant="inherit" className="text-slate-900 px-3">
                ข้อมูลตอบรับ
              </Typography>
            </Box>
            <Box className="grid grid-cols-2 px-3">
              <Typography
                variant="inherit"
                className="text-slate-700"
              >{`วันเวลานัด : ${dateToLocalDateString(
                referral.appointmentDateTime?.toString()
              )}`}</Typography>
              <Typography
                variant="inherit"
                className="text-slate-700"
              >{`จุดนัดหมาย : ${referral.appointmentPoint}`}</Typography>
            </Box>
            <Box className="px-3">
              <Typography
                variant="inherit"
                className="text-slate-700"
              >{`หมายเหตุ : ${referral.appointmentNotes}`}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ReferDetail;
