import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";

// mui
import { Box, Typography } from "@mui/material";

// constants
import { PENDING, REJECTED } from "../../../constants/Refers/referralStatus";
import { REFER_BACK } from "../../../constants/Refers/referType";

// functions
import { shortHospName } from "../../../functions/FuncPerjer";
import { getReferColor } from "../../../functions/Refers";

// types
import { IReferralPatientEMR } from "@/types/visit";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../../constants/Users/servicePermission";

// components
import AccordionReferral from "./AccordionReferral";
import MenuReferrals from "./Menu";
import { dateToLocalDateString } from "../../../functions/FuncDateTimes";

type PropsReferral = {
  referral: IReferralPatientEMR;
  patientFullName: { title: string; firstName: string; lastName: string };
  idToken: string;
  onRefresh(): void;
};
function VisitReferralItem({
  referral,
  idToken,
  patientFullName,
  onRefresh,
}: PropsReferral) {
  const {
    appData: { permissionRules },
  }: any = useSelector((state) => state);

  const [isExpendRequsetText, setIsExpendRequestText] = useState(true);
  const [isExpendOtherText, setIsExpendOtherText] = useState(true);
  const [isExpandTreatment, setIsExpandTreatment] = useState(true);
  const [isExpandPMH, setIsExpandPMH] = useState(true);
  const [isExpandPHI, setIsExpandPHI] = useState(true);

  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const onChangeRequestText = () =>
    setIsExpendRequestText(!isExpendRequsetText);
  const onChangeOtherText = () => setIsExpendOtherText(!isExpendOtherText);
  const onChangeTreatment = () => setIsExpandTreatment(!isExpandTreatment);
  const onChangePMH = () => setIsExpandPMH(!isExpandPMH);
  const onChangePHI = () => setIsExpandPHI(!isExpandPHI);
  const checkStatusRefer =
    referral.status === PENDING && referral.data.incomplete;
  const checkReferReject = referral.status === REJECTED;

  const expireDate = () => {
    if (referral.data.expireDate) {
      return dateToLocalDateString(referral.data.expireDate?.toString());
    }
    if (referral.data.referDateTime) {
      const dateOfDayjs = dayjs(referral.data.referDateTime).add(3, "months");
      return `${dateToLocalDateString(dateOfDayjs?.toString())} (อัตโนมัติ)`;
    }
    return "-";
  };

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  return (
    <Box
      component="div"
      // className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
      className={`${getReferColor({
        referral,
      })} flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3`}
    >
      <Box className="flex justify-between border-b-2 border-gray-200 h-10 px-5 py-1 items-center">
        <Typography
          variant="h6"
          color="#58595B"
        >{`Referral: ${referral.data.referoutId}`}</Typography>
        {ability && (
          <MenuReferrals
            bid={referral.bid}
            cid={referral.data.cid}
            attachments={referral.attachments}
            fromHospName={referral.fromHospital.hospName}
            idToken={idToken}
            title={patientFullName.title}
            firstName={patientFullName.firstName}
            lastName={patientFullName.lastName}
            onRefresh={onRefresh}
            referDocId={referral._id}
            fromHospCode={referral.data.fromHospCode}
            storageAttachment={referral.storageAttachment}
            status={referral.status}
            toHospCode={referral.data.toHospCode}
            actionRefer={ability.can(
              ServicePermissionEvent.UPDATE,
              ServicePermissionService.REFERRAL
            )}
          />
        )}
      </Box>
      <Box className="px-6 py-3">
        {referral.data.referNumber &&
          referral.data.referNumber !== referral.data.referoutId && (
            <Box>
              <Typography variant="inherit" className="text-slate-700">
                {`Refer Number : ${referral.data.referNumber}`}
              </Typography>
            </Box>
          )}

        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {checkStatusRefer
              ? "สถานะ : ข้อมูลไม่ครบถ้วน"
              : `สถานะ : ${referral.status}`}
          </Typography>
        </Box>
        {referral.rejectReason && (
          <Box>
            <Typography variant="inherit" className="text-slate-700">
              {`เหตุผลการปฏิเสธ: ${referral.rejectReason}`}
            </Typography>
          </Box>
        )}

        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`วันที่ส่งตัว : ${dateToLocalDateString(
              referral.data.referDateTime
            )}`}
          </Typography>
          <Typography
            variant="inherit"
            className="text-slate-700"
          >{`วันหมดอายุ : ${expireDate()}`}</Typography>
        </Box>
        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`รพ.ต้นทาง : ${referral.fromHospital.hospCode} ${shortHospName(
              referral.fromHospital.hospName
            )}`}
          </Typography>
          <Typography variant="inherit" className="text-slate-700">
            {`รพ.ปลายทาง : ${referral.toHospital.hospCode} ${shortHospName(
              referral.toHospital.hospName
            )}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {`Refer Type : ${
              referral.type === REFER_BACK ? "Refer back" : "Refer out"
            }`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {`สิทธิการรักษา : ${referral.data.pttypeName}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">
            {`ICD10 : ${referral.data.icd} - ${referral.data.icdName}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit" className="text-slate-700">{`${
            referral.type === REFER_BACK ? "Diagnosis" : "Pre Diagnosis"
          } : ${referral.data.preDiagnosis}`}</Typography>
        </Box>
        <Box>
          <Typography
            variant="inherit"
            className="text-slate-700"
          >{`ชื่อบุคลากรที่ส่งต่อ : ${
            referral.data.doctorName || " - "
          }`}</Typography>
        </Box>
        <Box>
          <Typography
            variant="inherit"
            className="text-slate-700"
          >{`เหตุผลการส่งต่อ : ${
            referral.data.reason || referral.data.refername || " - "
          }`}</Typography>
        </Box>
        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`ประเภทผู้ป่วย : ${referral.data.referoutTypeName || "-"}`}
          </Typography>
          <Typography variant="inherit" className="text-slate-700">
            {`ความเร่งด่วน : ${referral.data.referoutEmergencyTypeName || "-"}`}
          </Typography>
        </Box>
        <Box className="grid grid-cols-2">
          <Typography variant="inherit" className="text-slate-700">
            {`วันเวลาที่ตอบรับ : ${dateToLocalDateString(
              referral.statusLastUpdated?.toString()
            )}`}
          </Typography>
          <Typography variant="inherit" className="text-slate-700">
            {checkReferReject ? "ผู้ปฏิเสธ" : "ผู้ตอบรับ"}
            {`:${referral.statusLastUpdatedByName || "-"}`}
          </Typography>
        </Box>
        {referral.appointmentDateTime && (
          <Box className="border-2 border-gray-200 my-2 rounded-md">
            <Box className="border-b-2 border-gray-100">
              <Typography variant="inherit" className="text-slate-900 px-3">
                ข้อมูลตอบรับ
              </Typography>
            </Box>
            <Box className="grid grid-cols-2 px-3">
              <Typography
                variant="inherit"
                className="text-slate-700"
              >{`วันเวลานัด : ${dateToLocalDateString(
                referral.appointmentDateTime?.toString()
              )}`}</Typography>
              <Typography
                variant="inherit"
                className="text-slate-700"
              >{`จุดนัดหมาย : ${referral.appointmentPoint || ""}`}</Typography>
            </Box>
            <Box className="px-3">
              <Typography
                variant="inherit"
                className="text-slate-700"
              >{`หมายเหตุ : ${referral.appointmentNotes || ""}`}</Typography>
            </Box>
          </Box>
        )}
        {referral.data.requestText && (
          <AccordionReferral
            word={referral.data.requestText}
            title="REQUEST TEXT : "
            isExpand={isExpendRequsetText}
            onChange={onChangeRequestText}
          />
        )}
        {referral.data.otherText && (
          <AccordionReferral
            word={referral.data.otherText}
            title="OTHER TEXT : "
            isExpand={isExpendOtherText}
            onChange={onChangeOtherText}
          />
        )}

        {referral.data.treatmentText && (
          <AccordionReferral
            word={referral.data.treatmentText}
            title="TREATMENT : "
            isExpand={isExpandTreatment}
            onChange={onChangeTreatment}
          />
        )}

        {referral.data.pmh && (
          <AccordionReferral
            word={referral.data.pmh}
            title="Past medical history : "
            isExpand={isExpandPMH}
            onChange={onChangePMH}
          />
        )}
        {referral.data.hpi && (
          <AccordionReferral
            word={referral.data.hpi}
            title="History of present illness : "
            isExpand={isExpandPHI}
            onChange={onChangePHI}
          />
        )}
      </Box>
    </Box>
  );
}

export default VisitReferralItem;
