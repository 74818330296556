import React, { useState } from "react";
import { useSelector } from "react-redux";
// mui
import Drawer from "@mui/material/Drawer";
// components
import SideBar from "./SideBar";
import Appbar from "./Appbar";

export default function NavigationMain() {
  const { appData }: any = useSelector((state) => state);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side: string, open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  return (
    <>
      <Appbar
        loginData={appData?.loginData}
        fullName={appData?.loginData?.fullName}
        toggleDrawer={toggleDrawer}
      />
      <Drawer
        color="secondary"
        open={state.left}
        onClose={toggleDrawer("left", false)}
      >
        <SideBar toggleDrawer={toggleDrawer} logoUrl={appData?.customerConfig?.logoUrl} />
      </Drawer>
    </>
  );
}
