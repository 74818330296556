import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import fetchPatientsVisitsDetailsSet from "../../actions/fetchPatientsVisitsDetailsSet";
import { StrDecrypt } from "../../functions/FuncPerjer";
import { IVisit } from "@/types/visit";
import PatientVisit from "./patient";
import PatientInfo from "./patient/info/PatientInfo";
import VisitList from "./visit/VisitList";
import { useReactToPrint } from "react-to-print";
import DialogVisit from "./visit/DialogVisit";
interface IParams {
  cid: string;
  visitBid: string;
  hospCode: string;
  hn: string;
}
function PatientEmrLayout() {
  const { cid, hospCode, hn, visitBid } = useParams<IParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { appData, appProperty }: any = useSelector(
    (stateReducer) => stateReducer
  );
  const [visits, setVisits] = useState<IVisit[]>([]);
  const [visit, setVisit] = useState<null | IVisit>(null);
  const [visitIMP, setVisitIMP] = useState<null | IVisit>(null);
  const [value, setValue] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const cidDecript = StrDecrypt(cid, appProperty.ScKey);
  const fetchPatient = (limit = 10) => {
    let dataRequest = {
      idToken: appData?.idToken,
      hn,
      hospCode,
      limit,
      cid: "",
    };
    if (cidDecript !== "unknow") {
      dataRequest = { ...dataRequest, cid: cidDecript };
    }
    dispatch(fetchPatientsVisitsDetailsSet(dataRequest));
  };

  const onVisitABM = () => {
    const visitsAmb = appData.emrData.visits
      ?.filter((itemVisi: IVisit) => itemVisi.class === "AMB")
      .sort(
        (a: IVisit, b: IVisit) =>
          new Date(b.visitDateTime).getTime() -
          new Date(a.visitDateTime).getTime()
      );
    setVisits(visitsAmb);
  };
  const onSetVisit = (bidVisit: string) => {
    // find bid because visits already filter class amb done
    const tmpVisitsData = visits?.find(
      (visitItem) => visitItem.bid === bidVisit
    );
    setVisit(tmpVisitsData || null);
    // find IPD case in all visits from fetch patient
    if (tmpVisitsData?.an) {
      const tmpVisitsAdmitData = appData.emrData.visits?.find(
        (visitItem: IVisit) =>
          visitItem.an === tmpVisitsData.an && visitItem.class === "IMP"
      );
      setVisitIMP(tmpVisitsAdmitData || null);
    } else {
      setVisitIMP(null);
    }
  };
  const onRefersh = () => {
    setIsRefresh(!isRefresh);
  };

  // for fetch  patient
  useEffect(() => {
    if (cid && hospCode && hn) {
      fetchPatient(appProperty.visitLimit);
    }
  }, [cid, hospCode, hn, appProperty, isRefresh]);

  // for flitter class and sort
  useEffect(() => {
    onVisitABM();
  }, [appData]);

  // for set visit
  useEffect(() => {
    onSetVisit(visitBid);
  }, [visits, visitBid]);

  const onSelectVisit = (visitParams: IVisit) => {
    history.push(`/patient-emr/${cid}/${visitParams.bid}/${hospCode}/${hn}`);
    setValue(0);
  };
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    valueTab: any
  ) => {
    setValue(valueTab);
  };
  // Print Patient Emr page
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `cid:${appData.emrData.patients?.cid} name:${appData.emrData.patients?.data?.fullname?.firstName} lastname:${appData.emrData.patients?.data?.fullname?.lastName}`,
  });

  const handleDialogVisit = (status: boolean) => setOpen(status);
  return (
    <>
      {cid && hn && hospCode ? (
        <>
          <Grid
            container
            ref={componentRef}
            spacing={4}
            sx={{ paddingY: 10, paddingX: 4, "@media print": { paddingY: 2 } }}
          >
            <Grid item xs>
              <PatientInfo
                {...appData.emrData.patients}
                handlePrint={handlePrint}
              />
              {visit ? (
                <PatientVisit
                  visitAMB={visit}
                  visitIMP={visitIMP}
                  value={value}
                  onChangeTab={handleChange}
                  idToken={appData?.idToken}
                  patientFullName={appData.emrData.patients?.data?.fullname}
                  onRefresh={onRefersh} // for Menu.tsx
                  patient={appData.emrData.patients}
                  customerConfig={appData?.customerConfig}
                  userHospCode={appData?.loginData?.hospCode}
                  handleDialog={handleDialogVisit}
                />
              ) : (
                <Box display={"flex"} justifyContent={"center"} margin={10}>
                  <Typography variant="h5">ไม่พบรายการ Visit</Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={3} sx={{ display: { lg: "block", xs: "none" } }}>
              {appData.emrData?.visits?.length > 0 && (
                <VisitList
                  visits={visits}
                  onSelectVisit={onSelectVisit}
                  cid={cidDecript}
                  idVisit={visit?._id || appData.emrData.visits[0]?._id}
                />
              )}
            </Grid>
          </Grid>

          {appData.emrData?.visits?.length > 0 && (
            <DialogVisit
              visits={visits}
              onSelectVisit={onSelectVisit}
              cid={cidDecript}
              idVisit={visit?._id || appData.emrData.visits[0]?._id}
              open={open}
              handleDialog={handleDialogVisit}
            />
          )}
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"} marginY={14}>
          <Typography variant="h3">ไม่พบ CID</Typography>
        </Box>
      )}
    </>
  );
}

export default PatientEmrLayout;
