import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Language,
  Person,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StrDecrypt, StrEncrypt } from "../../functions/FuncPerjer";
import fetchCurrentUserInfo from "../../actions/fetchCurrentUserInfo.action";
import login from "../../actions/User/login.action";
import frontendConfig from "../../actions/frontendConfig.action";
import verifyTwoFactor from "../../actions/User/verifyTwoFactor.action";
import logout from "../../actions/User/logout.action";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import resendOTP from "../../actions/resendOTP.action";
import pdpa from "../../assets/pdf/pdpa.pdf";

const OTP_LENGTH = 6;
const COUNTDOWN = 60;

function UserLogin() {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation("common");

  const { appData }: any = useSelector((state) => state);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [open, setOpen] = useState(false);
  const [checkToken, setCheckToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [resendCountdown, setResendCountdown] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const otpInputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const fetchCurrentUserInfoAfterRefreshPage = async () => {
    try {
      dispatch(
        fetchCurrentUserInfo({
          idToken: StrDecrypt(localStorage.getItem("Token"), "CrIRWrUNC"),
        })
      );
    } catch (error) {
      localStorage.setItem("Token", "");
      localStorage.setItem("expireDate", "");
    }
  };

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onChangeEye = () => {
    setShowPassword(!showPassword);
  };

  const focusFirstOtpInput = () => {
    const firstInput = otpInputRefs.current[0];
    if (firstInput) {
      firstInput.focus();
    }
  };

  const handleTwoFactorLogin = async () => {
    const otpValue = otp.join("");

    if (!otpValue) {
      await dispatch(login({ username, password }));
      focusFirstOtpInput();
      setCountdown(COUNTDOWN);
      return;
    }

    if (otpValue.length !== OTP_LENGTH) {
      setLoading(false);
      return;
    }

    const idToken = appData.idToken;
    await dispatch(verifyTwoFactor({ otp: otpValue, idToken }));
    setOtp(Array(OTP_LENGTH).fill(""));
  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const isTwoFactorEnabled = appData.loginData?.security?.twoFactor.enabled;

      if (isTwoFactorEnabled) {
        await handleTwoFactorLogin();
      } else {
        await dispatch(login({ username, password }));
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  const setTextValueAfterRefresh = () => {
    if (!appData.loginData) {
      return;
    }

    setUsername(appData.loginData.username);
  };

  const handleOtpChange = (index: number, value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    const newOtp = otp.map((digit, i) => (i === index ? value : digit));
    setOtp(newOtp);
  };

  const handleKeyUp = (e: any, index: number) => {
    if (e.key === "Backspace" && index > 0) {
      const prevInput = otpInputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
      }
    } else if (e.key >= "0" && e.key <= "9") {
      if (index < otp.length - 1) {
        const nextInput = otpInputRefs.current[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const logoutApp = (previousUser: any) => {
    const payload = {
      loginData: null,
      loginStatus: false,
      idToken: "",
      FetchingStatus: false,
      previousUser,
    };

    localStorage.removeItem("expireDate");
    localStorage.setItem("Token", "");

    dispatch(logout(payload));
  };

  const handleLogin = ({ token }: { token: string }) => {
    localStorage.setItem("Token", StrEncrypt(token, "CrIRWrUNC"));
    setUsername("");
    setPassword("");
    setCountdown(null);
    setOpen(false);
  };

  const handleRefreshPage = () => {
    setTimeout(() => {
      setCheckToken(true);
      fetchCurrentUserInfoAfterRefreshPage();
    }, 100);
  };

  const handleTwoFactorExpireBeforeLoginExpire = () => {
    setCountdown(COUNTDOWN);
  };

  const onResendOtp = () => {
    if (resendCountdown > 0) {
      setIsResending(true);
      return;
    }

    const idToken = appData.idToken;
    dispatch(resendOTP({ idToken }));
    setIsResending(false);
    setResendCountdown(30);
    setCountdown(COUNTDOWN);
  };

  const handlePasteOTP = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData("text/plain");
    const pastedDigits = pastedData.match(/\d/g);

    if (pastedDigits && pastedDigits.length === otp.length) {
      const newOtp = pastedDigits.map((digit) => String(digit));
      setOtp(newOtp);
    }
  };

  const handleOpenPdf = () => {
    window.open(pdpa, "_blank");
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setResendCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [resendCountdown]);

  useEffect(() => {
    if (countdown === 0) {
      const username = appData.loginData.username;
      logoutApp(username);
      return;
    }

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown && prevCountdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  useEffect(() => {
    setOpen(true);
    setTextValueAfterRefresh();

    const token = appData.idToken;
    const isLoggedIn = appData.loginStatus;
    const loginData = appData.loginData;
    const lsToken = localStorage.getItem("Token");

    if (token && isLoggedIn) {
      handleLogin({ token });
    }

    if (lsToken && !loginData && !checkToken) {
      handleRefreshPage();
    }

    if (loginData && !isLoggedIn) {
      handleTwoFactorExpireBeforeLoginExpire();
    }
  }, [open, appData]);

  useEffect(() => {
    dispatch(frontendConfig());
  }, []);

  return (
    <Dialog fullWidth open={open} maxWidth="sm">
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={onSubmit}>
          <Grid container sx={{ minHeight: "500px" }}>
            <Grid
              item
              xs
              sx={{ display: { xs: "none", md: "block" } }}
              className="bg-gradient-to-b from-blue-200 to-blue-100"
            >
              <div className=" bg-[url('../src/assets/images/townImage.svg')] bg-no-repeat h-full relative">
                <Grid
                  container
                  display={"flex"}
                  flexDirection={"column"}
                  height={"100%"}
                >
                  <Grid item xs>
                    <img
                      src={
                        appData?.customerConfig?.logoUrl ||
                        "/logo_ever_healthcare.png"
                      }
                      className={
                        appData?.customerConfig?.logoUrl
                          ? "ml-6 mt-6 w-16"
                          : "ml-6 mt-6 w-46 h-14"
                      }
                      alt="logo_customer"
                    />
                  </Grid>

                  <Grid item xs="auto">
                    <Box display={"flex"} margin={1}>
                      <span className="text-xs text-slate-500 mt-2">
                        powered by{" "}
                      </span>
                      <img
                        src="/ever_logo.png"
                        className="ml-2 mt-1 w-14 h-5"
                        alt="logo_ever"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs>
              <Grid
                container
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
              >
                <Grid item xs="auto" alignSelf={"flex-end"} margin={1}>
                  <IconButton
                    onClick={() =>
                      i18n.language === "en"
                        ? i18n.changeLanguage("th")
                        : i18n.changeLanguage("en")
                    }
                  >
                    <Language className="text-white bg-sky-600 rounded-md shadow-md shadow-gray-400" />
                  </IconButton>
                </Grid>

                <Grid
                  item
                  xs
                  padding={2}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  <Typography
                    variant="h6"
                    className="text-sky-600"
                    fontWeight={"bold"}
                  >
                    {t("welcome.login")}
                  </Typography>
                  {appData.alert?.show && (
                    <Alert variant="filled" severity="error" className="mb-4">
                      <div>
                        {appData.alert?.msg?.indexOf(
                          "Request failed with status code 401"
                        ) === 0
                          ? "Username หรือ Password ไม่ถูกต้อง"
                          : "การ Login เข้าใช้งานมีปัญหา"}
                      </div>
                      <div>กรุณาลองใหม่อีกครั้ง !</div>
                    </Alert>
                  )}

                  <Stack gap={2} marginTop={2} marginBottom={4}>
                    <FormControl fullWidth>
                      <InputLabel>{t("welcome.username")}</InputLabel>
                      <OutlinedInput
                        value={username}
                        onChange={onUsernameChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <Person />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Username"
                        disabled={appData.loginData}
                      />
                    </FormControl>

                    {!appData.loginData?.security?.twoFactor.enabled && (
                      <FormControl fullWidth>
                        <InputLabel>{t("welcome.password")}</InputLabel>
                        <OutlinedInput
                          value={password}
                          onChange={onPasswordChange}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={onChangeEye}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label={t("welcome.password")}
                        />
                      </FormControl>
                    )}

                    {appData.loginData?.security?.twoFactor.enabled && (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        marginTop={2}
                      >
                        <Typography variant="caption">ยืนยัน OTP</Typography>
                        <Typography variant="caption" fontSize={"10px"}>
                          รหัส OTP จะหมดอายุ ภายในอีก{" "}
                          <b style={{ color: "#3366cc" }}>{countdown}</b> วินาที
                        </Typography>

                        <Stack direction={"row"} gap={1} marginY={1}>
                          {otp.map((digit, index) => (
                            <TextField
                              key={index}
                              id={`otp-input-${index}`}
                              type="text"
                              variant="outlined"
                              inputProps={{
                                style: { textAlign: "center" },
                                maxLength: 1,
                              }}
                              value={digit}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleOtpChange(index, e.target.value)
                              }
                              onKeyUp={(e: any) => handleKeyUp(e, index)}
                              onPaste={(
                                e: React.ClipboardEvent<HTMLInputElement>
                              ) => handlePasteOTP(e)}
                              inputRef={(ref) =>
                                (otpInputRefs.current[index] = ref)
                              }
                            />
                          ))}
                        </Stack>

                        <Box display={"flex"} gap={1}>
                          <Typography variant="caption">
                            ไม่ได้รับ OTP?
                          </Typography>
                          <Typography
                            variant="caption"
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={onResendOtp}
                          >
                            ส่ง OTP ให้ฉันอีกครั้ง
                          </Typography>
                        </Box>

                        {isResending && (
                          <Typography variant="caption" color="error">
                            กำลังดำเนินการส่ง OTP ให้คุณ กรุณารอสักครู่
                          </Typography>
                        )}
                      </Box>
                    )}

                    <LoadingButton
                      fullWidth
                      loading={loading}
                      loadingPosition="start"
                      startIcon={loading ? <SaveIcon /> : ""}
                      variant="contained"
                      type="submit"
                    >
                      {t("welcome.login")}
                    </LoadingButton>
                  </Stack>
                </Grid>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  paddingRight={1}
                >
                  <Typography
                    variant="caption"
                    sx={{ cursor: "pointer" }}
                    onClick={handleOpenPdf}
                  >
                    นโยบายการคุ้มครองข้อมูลส่วนบุคคล
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UserLogin;
