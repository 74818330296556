import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";

// mui
import {
  CreditCard,
  ExpandLess,
  ExpandMore,
  Home,
  SettingsApplications,
  SwapHoriz,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// types
import { ILinkList } from "./type";
import { menu, IMenuItem } from "../menu";
import { RootState } from "../../../reducers";
import { ServicePermissionEvent } from "../../../constants/Users/servicePermission";
import { useTranslation } from "react-i18next";

// components
import LinkListItem from "./LinkListItem";

function LinkList({ toggleDrawer }: ILinkList) {
  const { appData } = useSelector((state: RootState) => state);
  const { t } = useTranslation("common");

  const [openRefer, setOpenRefer] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const referList = menu.filter((item) => item.group === "referral");
  const billingList = menu.filter((item) => item.group === "billing");
  const settingList = menu.filter((item) => item.group === "setting");

  const collapseList = (open: boolean, list: IMenuItem[]) => (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List style={{ padding: "4px 24px" }}>
        {list.map(
          ({ text, icon, link, servicePermission }, index) =>
            // if it has servicePermission then check permission
            ((ability &&
              servicePermission &&
              servicePermission.some((service) =>
                ability.can(ServicePermissionEvent.READ, service)
              )) ||
              // if it hasn't servicePermission don't check permission and show munu normally
              !servicePermission) && (
              <LinkListItem
                text={text}
                Icon={icon}
                link={link}
                toggleDrawer={toggleDrawer}
                key={index}
              />
            )
        )}
      </List>
    </Collapse>
  );

  useEffect(() => {
    if (appData.permissionRules) {
      setAbility(new Ability(appData.permissionRules));
    }
  }, [appData.permissionRules]);

  return (
    <Box>
      <List>
        <ListItem
          button
          component={NavLink}
          to="/"
          exact
          activeClassName="ListItem-active"
          onClick={toggleDrawer("left", false)}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary={t("sidebar.home")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => setOpenRefer(!openRefer)}>
          <ListItemIcon>
            <SwapHoriz />
          </ListItemIcon>
          <ListItemText primary={t("sidebar.refer")} />
          {openRefer ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      {collapseList(openRefer, referList)}
      <List>
        <ListItem button onClick={() => setOpenBilling(!openBilling)}>
          <ListItemIcon>
            <CreditCard />
          </ListItemIcon>
          <ListItemText primary={t("sidebar.billing")} />
          {openBilling ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      <Divider />
      {collapseList(openBilling, billingList)}
      <List>
        <ListItem button onClick={() => setOpenSetting(!openSetting)}>
          <ListItemIcon>
            <SettingsApplications />
          </ListItemIcon>
          <ListItemText primary={t("sidebar.adminPanel")} />
          {openSetting ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      {collapseList(openSetting, settingList)}
    </Box>
  );
}

export default LinkList;
