import axios from "axios";
import { getApiUrl, isConfirmationExpired } from "../functions/Commons";

export default ({ idToken }: { idToken: string }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/user/current`;

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    })
    .then((res) => {
      const loginData = res.data;
      const twoFactor = loginData.security?.twoFactor;
      const isTwoFactorEnabled = twoFactor?.enabled;
      const lastConfirmationDateTime = twoFactor?.lastConfirmationDateTime;
      const isCheckTwoFactor =
        !lastConfirmationDateTime ||
        isConfirmationExpired(lastConfirmationDateTime);

      let loginStatus = true;
      if (isTwoFactorEnabled && isCheckTwoFactor) {
        loginStatus = false;
      }

      const dispatch = {
        idToken,
        loginData,
        loginStatus,
        status: loginData.status,
        FetchingStatus: false,
        hospCode: loginData.hospCode,
        permissionRules: loginData.rules,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
