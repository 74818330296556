import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";

// types
import { menu } from "../NavigationMain/menu";

// components
import CardItem from "./CardItem";
import { ServicePermissionEvent } from "../../constants/Users/servicePermission";

function Home() {
  const {
    appData: { permissionRules },
  }: any = useSelector((state) => state);
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const cardItem = () => {
    return menu.map(
      (card) =>
        // if it has servicePermission then check permission
        ((ability &&
          card.servicePermission &&
          card.servicePermission.some((service) =>
            ability.can(ServicePermissionEvent.READ, service)
          )) ||
          // if it hasn't servicePermission don't check permission and show munu normally
          !card.servicePermission) && (
          <CardItem key={`${card.text}-${card.description}`} {...card} />
        )
    );
  };

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  return (
    <div className="flex justify-center items-center mt-16 static">
      <div
        className="grid grid-cols-2 gap-5 my-6
        md:grid-cols-3"
      >
        {cardItem()}
      </div>
      <div className="hidden fixed z-10 left-3 bottom-1 font-bold sm:block">
        <div className="flex">
          <img src="/ever_logo.png" className="h-8 mr-2" alt="logo" />
          <span className="self-end">Powered By Ever</span>
        </div>
      </div>
    </div>
  );
}

export default Home;
