import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// mui
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

// actions
import acceptRefer from "../../../actions/Refers/acceptRefer";
import rejectRefer from "../../../actions/Refers/rejectRefer";

// components
import {
  ReferAcceptDialog,
  // ReferAttachDialog,
  ReferRejectDialog,
} from "../../Utility/Refers";

// types
import { RootState } from "../../../reducers";
import ReferralStorageAttachDialog from "../../Utility/Refers/ReferAttachDialog/ReferralStorageAttachDialog";
import ReferPreviewAttachDialog from "../../Utility/Refers/ReferAttachDialog/ReferPreviewAttachDialog";
import { IReferralStorageAttachment } from "@/types/refers";
import { PENDING } from "../../../constants/Refers/referralStatus";
import HistoryReferDialog from "../../Utility/Refers/historyDialog";

interface IMenuReferral {
  cid: string;
  bid: string;
  title: string;
  firstName: string;
  lastName: string;
  attachments: { ipfs_file_hash: string; ipfs_file_name: string }[];
  idToken: string;
  fromHospName: string;
  onRefresh(): void;
  referDocId: string;
  fromHospCode: string;
  storageAttachment: IReferralStorageAttachment[];
  status: string;
  toHospCode: string;
  actionRefer: boolean;
}
function MenuReferrals({
  cid,
  bid,
  title,
  firstName,
  lastName,
  fromHospCode,
  idToken,
  fromHospName,
  onRefresh,
  referDocId,
  storageAttachment,
  status,
  toHospCode,
  actionRefer,
}: IMenuReferral) {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [DialogAcceptOpen, setDialogAcceptOpen] = useState(false);
  const [DialogRejectOpen, setDialogRejectOpen] = useState(false);
  const [DialogAttachOpen, setDialogAttachOpen] = useState(false);
  const [DialogPreviewOpen, setDialogPreviewOpen] = useState(false);
  const [DialogHistoryOpen, setDialogHistoryOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DialogAcceptOpenFc = () => {
    setDialogAcceptOpen(true);
    handleClose();
  };

  const DialogRejectOpenFc = () => {
    setDialogRejectOpen(true);
    handleClose();
  };

  const DialogAttachOpenFc = () => {
    setDialogAttachOpen(true);
    handleClose();
  };

  const DialogPreviewOpenFc = () => {
    setDialogPreviewOpen(true);
    handleClose();
  };
  const DialogHistoryOpenFc = () => {
    setDialogHistoryOpen(true);
    handleClose();
  };
  const DialogHistoryCloseFc = () => {
    setDialogHistoryOpen(false);
    handleClose();
  };

  const ReferAcceptSubmit = (appointmentInfo: any) => {
    try {
      if (bid) {
        const dataRequest = {
          ...appointmentInfo,
          idToken,
          bid,
        };
        dispatch(acceptRefer(dataRequest));
        onRefresh();
        setDialogAcceptOpen(false);
      }
    } catch (error) {
      alert("การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
    }
  };

  const ReferRejectSubmit = (reason: string) => {
    if (!reason) {
      alert("คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !");
      return;
    }
    if (bid) {
      try {
        const dataRequest = {
          idToken,
          bid,
          reason,
        };
        dispatch(rejectRefer(dataRequest));
        onRefresh();
        setDialogRejectOpen(false);
      } catch (error) {
        alert("การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
      }
    }
  };
  const DialogAttachCloseFc = () => {
    setDialogAttachOpen(false);
    onRefresh();
  };
  const DialogPreviewCloseFc = () => {
    setDialogPreviewOpen(false);
    onRefresh();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      {actionRefer && (
        <>
          {" "}
          <div className="print:hidden">
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="refer-action-menu"
          >
            <div>
              {status.toLocaleUpperCase() === PENDING &&
                toHospCode === appData.loginData.hospCode && (
                  <>
                    <MenuItem onClick={() => DialogAcceptOpenFc()}>
                      Accept (ยอมรับ Refer)
                    </MenuItem>
                    <MenuItem onClick={() => DialogRejectOpenFc()}>
                      Reject (ปฏิเสธ Refer)
                    </MenuItem>
                  </>
                )}

              {!appData?.customerConfig?.disabledFeatures.includes(
                "attachFile"
              ) &&
                appData.loginData.hospCode === fromHospCode && (
                  <MenuItem onClick={() => DialogAttachOpenFc()}>
                    Attachment(แนบไฟล์)
                  </MenuItem>
                )}
              {!appData?.customerConfig?.disabledFeatures.includes(
                "attachFile"
              ) &&
                !appData?.customerConfig?.disabledFeatures.includes(
                  "toHosptailAttach"
                ) &&
                appData.loginData.hospCode === toHospCode && (
                  <MenuItem onClick={() => DialogAttachOpenFc()}>
                    Attachment(แนบไฟล์)
                  </MenuItem>
                )}
              {!appData?.customerConfig?.disabledFeatures.includes(
                "attachFile"
              ) && (
                <MenuItem onClick={() => DialogPreviewOpenFc()}>
                  Preview({storageAttachment?.length || 0})
                </MenuItem>
              )}
              {!appData?.customerConfig?.disabledFeatures.includes(
                "historyRefer"
              ) && (
                <MenuItem onClick={() => DialogHistoryOpenFc()}>
                  ประวัติ
                </MenuItem>
              )}
            </div>
          </Menu>
          <ReferAcceptDialog
            open={Boolean(DialogAcceptOpen)}
            cid={cid}
            ptname={`${title} ${firstName} ${lastName}`}
            hospName={fromHospName}
            onClose={() => setDialogAcceptOpen(false)}
            onSubmit={(appointmentInfo) => ReferAcceptSubmit(appointmentInfo)}
          />
          <ReferRejectDialog
            open={Boolean(DialogRejectOpen)}
            cid={cid}
            ptname={`${title} ${firstName} ${lastName}`}
            hospName={fromHospName}
            onClose={() => setDialogRejectOpen(false)}
            onSubmit={(reason) => ReferRejectSubmit(reason)}
          />
          <ReferralStorageAttachDialog
            open={Boolean(DialogAttachOpen)}
            onClose={() => DialogAttachCloseFc()}
            token={idToken}
            referDocId={referDocId}
          />
          <ReferPreviewAttachDialog
            open={Boolean(DialogPreviewOpen)}
            onClose={() => DialogPreviewCloseFc()}
            storageAttachment={storageAttachment}
            token={idToken}
            fromHospCode={fromHospCode}
            userHospCode={appData.loginData.hospCode}
          />
          <HistoryReferDialog
            token={idToken}
            referBid={bid}
            open={Boolean(DialogHistoryOpen)}
            onClose={() => DialogHistoryCloseFc()}
          />
          {/* <ReferAttachDialog
        idToken={idToken}
        open={Boolean(DialogAttachOpen)}
        onClose={() => DialogAttachCloseFc()}
        bid={bid}
        attachments={attachments}
      /> */}
        </>
      )}
    </div>
  );
}

export default MenuReferrals;
