import { Close } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React from "react";
import LinkList from "./LinkList";
import { ISideBar } from "./type";

const index = ({ toggleDrawer, logoUrl }: ISideBar) => (
  <div
    className="navbar-left w-72 grid m-0 h-full"
    role="presentation"
    onKeyDown={toggleDrawer("left", false)}
  // style={{ width: "272px",position:'relative' }}
  >
    <div>
      <Grid container justifyContent="center" marginTop={2}>
        <Grid item xs={8} container justifyContent="center">
          <img
            alt="logo-customer"
            src={logoUrl || "/ever_logo.png"}
            width={logoUrl ? "38.6px" : "100px"}
            height="33.05px"
            style={{ maxWidth: "100%", left: logoUrl? '117px':'70px', position: 'absolute' }}
          />
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          <IconButton onClick={toggleDrawer("left", false)}>
            <Close fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid>
        <LinkList toggleDrawer={toggleDrawer} />
      </Grid>
    </div>
    <div className="flex justify-center items-end gap-2 p-0">
      <div className="fixed-bottom left-4 top-96">
        <div className="flex">
          <span className="text-xs text-slate-500 my-5">powered by </span>
          <img
            src="/ever_logo.png"
            className="ml-2 my-4 w-14 h-5"
            alt="logo_ever"
          />
        </div>
      </div>
    </div>
  </div>
);

export default index;
