/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

// mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

// constants
import { REFER_IN, REFER_BACK } from "../../../constants/Refers/referType";

// styles
import "../../../styles/refers/ReferAcceptDialog.scss";

type TDialogAcceptAppointment = {
  open: boolean;
  cid: string;
  ptname: string;
  hospName: string;
  onClose(): void;
  onSubmit(appointmentInfo: {
    appointmentPoint: string;
    appointmentNotes: string;
  }): void;
};
export default function AppointmentAcceptDialog(
  props: TDialogAcceptAppointment
) {
  const { handleSubmit, control } = useForm();

  const { open, cid, ptname, hospName, onClose, onSubmit } = props;

  const submit = (referData: any) => {
    onSubmit(referData);
  };

  return (
    <Dialog
      fullWidth
      id="refer-accept-dialog"
      maxWidth="xs"
      open={open}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit((e) => submit(e))}>
        <DialogTitle>
          <CheckCircleIcon className="bg-green-100 p-1 rounded-full text-green-500 mr-2 w-8 h-8" />
          ยืนยันการรับ Appointment
          <IconButton
            className="float-right"
            onClick={() => onClose()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {cid && <DialogContentText>CID : {cid}</DialogContentText>}
          {ptname && <DialogContentText>ผู้ป่วย : {ptname}</DialogContentText>}
          {hospName && <DialogContentText>จาก : {hospName}</DialogContentText>}

          <Controller
            as={
              <TextField
                fullWidth
                margin="dense"
                // required={referType === REFER_IN}
                label="จุดนัดหมาย"
                variant="outlined"
                size="small"
              />
            }
            control={control}
            name="appointmentPoint"
          />

          <Controller
            as={
              <TextField
                fullWidth
                multiline
                margin="dense"
                label="หมายเหตุ"
                variant="outlined"
                size="small"
              />
            }
            control={control}
            name="appointmentNotes"
          />

          <Button
            fullWidth
            id={REFER_IN}
            type="submit"
            size="small"
            className="!bg-emerald-500 !text-white !mt-3
              hover:!bg-emerald-600"
          >
            รับ Refer
          </Button>

          <Button
            fullWidth
            id={REFER_BACK}
            size="small"
            className="!mt-3 !text-sky-600
            hover:!bg-transparent"
            onClick={() => onClose()}
          >
            ยกเลิก
          </Button>
        </DialogContent>
      </form>
    </Dialog>
  );
}
