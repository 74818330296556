import React, { useCallback, useEffect, useState } from "react";

// mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { getHistoryReferId } from "../../../api/ReferHistory";
import { IHistoryRefer } from "@/types/historyRefer";
import { Card } from "@mui/material";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { DocumentScannerOutlined } from "@mui/icons-material";
import { dateToLocalDateString } from "../../../functions/FuncDateTimes";

interface IHistoryReferDialog {
  open: boolean;
  onClose(): void;
  token: string;
  referBid: string;
}

const HistoryReferDialog = ({
  open,
  onClose,
  referBid,
  token,
}: IHistoryReferDialog) => {
  const histories = useFetchHistoriesRefer(referBid, token);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="refer-dialog-attach bg-transparent"
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      {/* <DialogTitle id="alert-dialog-title" className="">
        <strong></strong>
      </DialogTitle> */}
      <DialogContent className="bg-gray-100 py-4">
        {histories.length > 0 && (
          <p className="flex justify-center text-xl text-blue-400 my-4">
            ประวัติการเปลี่ยนแปลงข้อมูล รีเฟอร์
          </p>
        )}
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
          position="alternate"
        >
          {histories?.map(
            (history) =>
              history.topic && (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      <DocumentScannerOutlined />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card sx={{ borderRadius: "10px" }}>
                      <div className="mx-4 my-3">
                        <p className="flex justify-end text-sm text-gray-500">
                          {dateToLocalDateString(history.createdAt)}
                        </p>
                        <p className="flex justify-start">{history.topic}</p>
                        <p className="flex justify-start text-xs text-gray-400">
                          {history.updatedBy}
                        </p>
                      </div>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              )
          )}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
};
const useFetchHistoriesRefer = (referBid: string, token: string) => {
  const [histories, setHistories] = useState<IHistoryRefer[]>([]);
  const getAllPatients = useCallback(async () => {
    const data = await getHistoryReferId(token, referBid);

    setHistories(data);
  }, [referBid]);

  useEffect(() => {
    getAllPatients();
  }, [getAllPatients]);

  return histories;
};

export default HistoryReferDialog;
