export function getApiUrl() {
  const hostname = window.location.hostname;

  if (hostname !== "localhost") {
    const apiUrl = "https://" + hostname.replace("refer", "refer-api");

    return apiUrl;
  } else {
    return "http://localhost:3010";
  }
}

export function isConfirmationExpired(
  dateTime: string | number | Date
): boolean {
  const confirmationExpirationTime = new Date(dateTime);
  confirmationExpirationTime.setHours(
    confirmationExpirationTime.getHours() + 2
  );
  return new Date() > confirmationExpirationTime;
}
